<template>
  <div class="print_tag_wapper ph16">
    <breadcrumb
      :title="$route.query.line || translateLang('main.sideBar.paibanResult')"
      :childTitle="translateLang('main.sideBar.printTag')"
      @goBackPage="$router.push({ path: '/newPaiban' })"
      class="breadcrumb-box"
    >
    </breadcrumb>

    <div class="header relative flex flex-cross--center pl16 bg-white">
      <span class="title fs16 color-o-9 bold">{{
        $t('main.sideBar.printTag')
      }}</span>
      <div class="select-tag">
        <span>{{ $t('common.filter') }}：</span>
        <el-select
          size="mini"
          v-model="cut_select_value"
          @change="siftChange"
          :disabled="isTagLoading"
        >
          <el-option
            v-for="it in select_options"
            :key="it.value"
            :label="it.label"
            :value="it.value"
          >
          </el-option>
        </el-select>
      </div>
      <a-progress
        status="active"
        v-if="isTagLoading"
        :percent="cut_progress"
        id="printTag_progress_span"
        :stroke-color="{
          '0%': '#108ee9',
          '100%': '#87d068',
        }"
        size="small"
        class="progress"
      />
      <span v-if="isTagLoading" class="ml15" style="color: red">
        {{ $t('printPage.genTagFile') }}
      </span>
      <span
        v-if="isOneCodeErr"
        class="inline-block ml20"
        style="color: #ff0000"
      >
        {{ translateLang('common.oneCodeErrTip') }}
      </span>
      <div
        class="header-right absolute"
        @mouseenter="() => (isShowToolTip = true)"
        @mouseleave="() => (isShowToolTip = false)"
      >
        <el-tooltip
          :content="translateLang('printPage.waitGen')"
          placement="bottom"
          :value="isCanDownLoading && isShowToolTip"
          enterable
          manual
        >
          <div class="print_btn">
            <a-button
              :disabled="isCanDownLoading"
              :loading="export_loading"
              class="export-btn export-pdf-btn mr16"
              id="print_tag_pdf_btn"
              @click="exportPdf"
              >{{
                export_loading
                  ? export_loading_text
                  : translateLang('common.exportPDF')
              }}</a-button
            >
            <a-button
              :disabled="isCanDownLoading"
              :loading="export_loading"
              class="export-btn mr16"
              id="print_tag_pic_btn"
              @click="exportImg"
              :class="{
                'disabled-btn':
                  print_color_tag &&
                  $store.state.ncSetting.labelImageFormatNew == 'bmp' &&
                  $store.state.ncSetting.bmp_color_style == 1,
              }"
              >{{
                export_loading
                  ? export_loading_text
                  : translateLang('common.exportPic')
              }}
              <a-tooltip placement="top">
                <template slot="title">
                  <span>{{ translateLang('common.hoverTip') }}</span>
                </template>
                <img
                  src="@/assets/labelColor/error-circle.png"
                  alt=""
                  style="height: 16px; width: 16px"
                  class="ml6 mb3"
                  v-if="
                    print_color_tag &&
                    $store.state.ncSetting.labelImageFormatNew == 'bmp' &&
                    $store.state.ncSetting.bmp_color_style == 1
                  "
                />
              </a-tooltip>
            </a-button>

            <a-button
              :disabled="isCanDownLoading"
              :loading="export_loading"
              class="export-btn print-tag-btn"
              id="print_tag_export_btn"
              @click="printPre"
            >
              {{
                export_loading
                  ? export_loading_text
                  : translateLang('printPage.print')
              }}
              <img
                src="@/assets/labelColor/error-circle.png"
                alt=""
                style="height: 16px; width: 16px"
                class="ml6 mb4 print-tag-img"
                v-show="print_color_tag"
              />
              <div class="absolute print-tip" v-show="print_color_tag">
                <span class="color-tip" style="color: #000000e6">{{
                  translateLang('common.checkColorPrint')
                }}</span>
              </div>
            </a-button>
          </div>
        </el-tooltip>
      </div>
    </div>

    <a-spin
      :tip="translateLang('printPage.tempLoading')"
      :spinning="template_loading"
      :delayTime="100"
    >
      <div class="com_content relative">
        <!-- 标签模板 -->
        <div class="tag_setTem_wap y-auto">
          <div class="tag_tem_wap y-auto">
            <p class="title">{{ $t('common.tagTemp') }}</p>
            <div class="tag_tem_list pr15 y-auto">
              <div
                v-for="(template, index) in template_list"
                :key="index"
                class="cursor-pointer color-o-9"
                :class="{ active: template.id === active_template_id }"
                @click="chooseTemplate(template)"
              >
                <a-tooltip>
                  <template #title v-if="template.name.length > 17">{{
                    template.name
                  }}</template>
                  <span class="template-name w100 overflow-hidden ellipsis">{{
                    index
                      ? template.name
                      : translateLang('printPage.defaultTemplate')
                  }}</span>
                </a-tooltip>
              </div>
            </div>
            <div class="edit_addTem mt10">
              <a-button
                v-show="active_template_id"
                @click="editTemplate"
                id="print_tag_edit_template_btn"
                :title="translateLang('printPage.editTemplate')"
                class="pt5"
                style="width: 88px"
                ><span class="w100 ellipsis inline-block">{{
                  $t('printPage.editTemplate')
                }}</span></a-button
              >
              <a-button
                style="margin-left: 6px; width: 70px"
                @click="addTemplate"
                id="print_tag_add_template_btn"
                class="pt5"
                :title="translateLang('common.add')"
                ><span s class="w100 ellipsis inline-block">{{
                  $t('common.add')
                }}</span></a-button
              >
              <a-button
                v-show="active_template_id"
                style="margin-left: 6px; width: 70px"
                @click="deleteTemplate"
                id="print_tag_delete_template_btn"
                class="pt5"
                :title="translateLang('common.delete')"
                ><span class="w100 ellipsis inline-block">{{
                  $t('common.delete')
                }}</span></a-button
              >
            </div>
          </div>
          <div class="tag_set">
            <p class="title">
              <span
                class="inline-block ellipsis"
                style="max-width: 85px"
                :title="translateLang('printPage.tagSetting')"
                >{{ $t('printPage.tagSetting') }}</span
              >
              <span
                class="fs12 color-o-4 ml5 inline-block ellipsis text-style"
                style="width: 170px"
                :title="translateLang('printPage.rememberSetting')"
                >{{ $t('printPage.rememberSetting') }}</span
              >
            </p>

            <div>
              <p>
                <a-checkbox
                  v-model="gen_small_plank_tag"
                  @change="changeGenSmallPlankTag"
                  ><span
                    class="inline-block ellipsis text-style"
                    :title="translateLang('printPage.genPartTag')"
                    >{{ $t('printPage.genPartTag') }}
                  </span></a-checkbox
                >
              </p>
              <p>
                <a-checkbox
                  id="printTag_gen_cutingTag_checkbox"
                  v-model="cut_need_lave"
                  @change="changeCutNeedLave"
                  ><span
                    id="printTag_gen_cutingTag_checkbox_label"
                    class="inline-block ellipsis text-style"
                    :title="translateLang('printPage.genCutingTag')"
                    >{{ $t('printPage.genCutingTag') }}</span
                  ></a-checkbox
                >
              </p>
              <p>
                <a-checkbox
                  v-model="need_lave"
                  @change="changeNeedLave"
                  id="printTag_gen_surplusTag_checkbox"
                  ><span
                    id="printTag_gen_surplusTag_checkbox_label"
                    class="inline-block ellipsis text-style"
                    :title="translateLang('printPage.genSurplusTag')"
                    >{{ $t('printPage.genSurplusTag') }}</span
                  ></a-checkbox
                >
              </p>
              <div style="height: 90px">
                <a-checkbox
                  v-model="gen_big_plank_tag"
                  @change="changeGenBigPlankTag"
                  >{{ $t('printPage.genPlankTag') }}
                </a-checkbox>
                <div class="label-position mt6" @click.stop.prevent="() => {}">
                  <span
                    class="inline-block text-style ellipsis"
                    style="max-width: 52px"
                    :title="translateLang('printPage.tagPosition')"
                    >{{ $t('printPage.tagPosition') }}</span
                  >
                  <span
                    class="color-o-4 inline-block text-style ellipsis"
                    style="max-width: 170px"
                    :title="translateLang('printPage.tagPositionTip')"
                    >{{ $t('printPage.tagPositionTip') }}</span
                  >
                  <div class="flex mt5">
                    <div
                      class="coord-box flex flex-cross--center br3 pl8 mr12 mt2"
                    >
                      <span
                        class="fs12 color-o-4 pt1 ellipsis inline-block"
                        style="max-width: 32px"
                        :title="translateLang('printPage.Xposition')"
                        >{{ $t('printPage.Xposition') }}</span
                      >
                      <a-input-number
                        v-model="xCoordinate"
                        :min="-1000"
                        :max="1000"
                        :step="1"
                        :precision="0"
                        :disabled="!gen_big_plank_tag"
                        style="width: 62px; height: 24px"
                        @change="handleXChange"
                      />
                    </div>
                    <div class="coord-box flex flex-cross--center br3 pl8 mt2">
                      <span
                        class="fs12 color-o-4 pt1 ellipsis inline-block"
                        style="max-width: 32px"
                        :title="translateLang('printPage.Yposition')"
                        >{{ $t('printPage.Yposition') }}</span
                      >
                      <a-input-number
                        v-model="yCoordinate"
                        :min="-1000"
                        :max="1000"
                        :step="1"
                        :precision="0"
                        :disabled="!gen_big_plank_tag"
                        style="width: 62px; height: 24px"
                        @change="handleYChange"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <p>
                <a-checkbox
                  v-model="isGenNcSuffix"
                  @change="handleChangeNCSuffix"
                  ><span
                    class="inline-block ellipsis text-style"
                    :title="translateLang('printPage.genPlankSuffix')"
                    >{{ $t('printPage.genPlankSuffix') }}</span
                  ></a-checkbox
                >
              </p>
              <p>
                <a-checkbox v-model="jx_checked" @change="changeJxChecked">
                  <span
                    class="inline-block ellipsis text-style"
                    :title="translateLang('printPage.arrangeMirror')"
                    >{{ $t('printPage.arrangeMirror') }}</span
                  ></a-checkbox
                >
              </p>
              <p>
                <a-checkbox v-model="five_six_dir" @change="changeFiveSixDir"
                  ><span
                    class="inline-block ellipsis text-style"
                    :title="translateLang('printPage.arrangeMirror')"
                    >{{ $t('printPage.drillPlankBase') }}</span
                  ></a-checkbox
                >
              </p>
            </div>

            <div class="tag_sort_check">
              <p
                class="fs14 color-o-9 mb5 ellipsis text-style"
                :title="translateLang('printPage.suitAuto')"
                style="width: 250px"
              >
                {{ $t('printPage.suitAuto') }}
              </p>
              <a-radio-group
                name="radioGroup"
                v-model="sort_value"
                class="ml20 flex"
                style="width: 220px"
                @change="changeSortValue"
              >
                <a-radio :value="1"
                  ><span
                    class="inline-block ellipsis text-style"
                    style="width: 28px"
                    :title="translateLang('printPage.default')"
                    >{{ $t('printPage.default') }}</span
                  ></a-radio
                >
                <a-radio :value="2"
                  ><span
                    class="inline-block ellipsis text-style"
                    style="width: 42px"
                    :title="translateLang('printPage.up')"
                    >{{ $t('printPage.up') }}↑</span
                  ></a-radio
                >
                <a-radio :value="3"
                  ><span
                    class="inline-block ellipsis text-style"
                    style="width: 42px"
                    :title="translateLang('printPage.down')"
                    >{{ $t('printPage.down') }}↓</span
                  ></a-radio
                >
              </a-radio-group>
            </div>

            <!-- 生成彩色标签 -->
            <div class="tag_color_check">
              <a-checkbox
                v-model="print_color_tag"
                @change="handleChangeColorTag"
              >
                <new-content>
                  <span
                    class="inline-block ellipsis text-style"
                    :title="translateLang('printPage.colorLabel')"
                    >{{ $t('printPage.colorLabel') }}
                    <img
                      src="@/assets/labelColor/error-circle.png"
                      alt=""
                      id="parintTag2-error-circle"
                      style="width: 16px; height: 16px"
                      class="error-circle"
                    />
                  </span>
                </new-content>
              </a-checkbox>
              <!-- 彩色标签hover时展示提示 -->
              <div
                class="label-color-pop p16 absolute"
                id="printTag2-color-pop"
              >
                <p class="label-color-phead mb16">
                  {{ translateLang('common.colorRules') }}
                </p>
                <!-- 遍历 -->
                <div
                  v-for="(item, index) in colorTagTips"
                  :key="index"
                  class="flex"
                >
                  <div class="mb16">
                    <p class="label-color-phead label-color-title mb6">
                      {{ translateLang(item.title) }}
                    </p>
                    <span class="fs12 label-color-span"
                      >{{ translateLang('common.greyPart')
                      }}<span style="color: #e34d59">{{
                        `${translateLang(item.title)}${translateLang(
                          'common.region'
                        )}`
                      }}</span
                      >，
                      <span v-if="item.id !== 4">
                        {{
                          $t('common.notSame', {
                            regionName: translateLang(item.title),
                          })
                        }}</span
                      >
                      <span v-else>
                        {{ translateLang('common.doorTip') }}
                      </span>
                    </span>
                  </div>
                  <img :src="item.imgUrl" alt="" class="label-color-img" />
                </div>
              </div>
            </div>
          </div>
          <div class="print_set">
            <p class="title">
              <span
                class="inline-block ellipsis"
                style="max-width: 85px"
                :title="translateLang('printPage.printSetting')"
                >{{ $t('printPage.printSetting') }}</span
              >
              <span
                class="fs12 color-o-4 ml5 inline-block ellipsis text-style"
                style="width: 170px"
                :title="translateLang('printPage.rememberSetting')"
                >{{ $t('printPage.rememberSetting') }}</span
              >
            </p>
            <div>
              <p class="mb12 color-0">
                <span
                  class="text-style ellipsis inline-block"
                  style="max-width: 58px"
                  :title="translateLang('printPage.oneToMore')"
                  >{{ $t('printPage.oneToMore') }}</span
                >：<span
                  class="inline-block ellipsis text-style"
                  :title="translateLang('printPage.perPage')"
                  >{{ $t('printPage.perPage') }}</span
                >
                <a-input-number
                  v-model="print_setting.cols"
                  :min="1"
                  :max="10"
                  :step="1"
                  :precision="0"
                  style="width: 60px; margin-right: 5px"
                  @change="handleColsChange"
                />{{ $t('printPage.coloumn') }}
              </p>
              <p class="mb12 color-0">
                <span
                  class="text-style ellipsis inline-block"
                  style="max-width: 58px"
                  :title="translateLang('printPage.tagMargin')"
                  >{{ $t('printPage.tagMargin') }}</span
                >：<span
                  class="text-style ellipsis inline-block"
                  :title="translateLang('printPage.coloumnMargin')"
                  >{{ $t('printPage.coloumnMargin') }}</span
                >
                <a-input-number
                  v-model="print_setting.labelColumnGap"
                  :min="0"
                  :max="999.99"
                  :step="1"
                  style="width: 80px; margin-right: 5px"
                  @change="handleColSpaceChange"
                />mm
              </p>
              <p class="flex flex-cross--center mb10 color-0">
                <span
                  class="text-style ellipsis inline-block"
                  style="max-width: 58px"
                  :title="translateLang('printPage.specialMark')"
                  >{{ $t('printPage.specialMark') }}</span
                >：<span
                  class="text-style ellipsis inline-block"
                  :title="translateLang('printPage.opacity')"
                  >{{ $t('printPage.opacity') }}</span
                >
                <a-slider
                  v-model="print_setting.opacity"
                  :min="0"
                  :max="1"
                  :step="0.01"
                  class="mr5"
                  style="width: 88px"
                  :disabled="$store.state.isExistcusSpecialTag"
                  @change="handleConfigSlider"
                />
                <span>{{ print_setting.opacity }}</span>
              </p>
              <p class="flex flex-cross--center mb10 color-0">
                <span
                  class="text-style ellipsis inline-block"
                  style="max-width: 58px"
                  :title="translateLang('printPage.suite')"
                  >{{ $t('printPage.suite') }}</span
                >
                <a-switch
                  size="small"
                  v-model:checked="print_setting.sizeAuto"
                  class="ml5 mr3"
                  :disabled="$store.state.isExistcusSpecialTag"
                  @change="handleConfigSlider"
                />
                {{ $t('printPage.size') }}
                <a-slider
                  v-model="print_setting.size"
                  :disabled="
                    $store.state.isExistcusSpecialTag || print_setting.sizeAuto
                  "
                  :min="0"
                  :max="1"
                  :step="0.01"
                  class="mr5"
                  style="width: 88px"
                  @change="handleConfigSlider"
                />
                <span>{{ print_setting.size }}</span>
              </p>
            </div>
          </div>
        </div>

        <!-- 标签列表 -->
        <div
          v-loading="isreloading"
          class="tag_canvas_wap"
          :class="renderDataList.length > 5 ? 'tag_canvas_wap--height' : ''"
        >
          <div
            v-if="!tag_svg_list.length && renderDataList.length"
            class="loading_page-wrap"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
            "
          >
            <i
              class="el-icon-loading"
              style="color: #18a8c7; font-size: 25px"
            ></i>
          </div>
          <div
            :style="{
              width: `${tag_canvas_wap_width.total_width}px`,
            }"
          >
            <!-- 使用svg时 -->
            <div
              class="tag_list print_tag_list"
              v-infinite-scroll="loadTagList"
              :infinite-scroll-disabled="
                useLoadMore ? disabledTagList : () => {}
              "
              :infinite-scroll-immediate="false"
            >
              <div
                v-for="(item, idx) in tag_svg_list"
                :key="idx"
                :data-idx="idx"
                class="print_mark"
              >
                <div
                  v-if="idx < tagListSourceData.length"
                  style="margin-bottom: 20px"
                  class="render_svg_item"
                  :style="{
                    'margin-right': `${
                      (Number(idx) + 1) % print_setting.cols
                        ? tag_canvas_wap_width.space
                        : 0
                    }px`,
                  }"
                  v-html="item"
                ></div>
                <div
                  v-else
                  style="margin-bottom: 20px"
                  class="render_svg_item"
                  :style="{
                    'margin-right': `${
                      (tagListSourceData.length +
                        (idx - tagListSourceData.length) +
                        1) %
                      print_setting.cols
                        ? tag_canvas_wap_width.space
                        : 0
                    }px`,
                  }"
                  v-html="item"
                ></div>
              </div>
              <!-- 占位用，处理用滚轮滚动时，加载更多的时候，loading没有显示完整的问题 -->
              <p
                class="place"
                v-if="!isTagLoading && !isNoMoreTag"
                style="width: 100%; height: 21px"
              ></p>
            </div>
            <p v-if="tag_svg_list.length && isTagLoading">
              <i class="el-icon-loading"></i>
              {{ $t('arrangedPage.dataLoading') }}
            </p>
            <p v-if="tag_svg_list.length && isNoMoreTag">
              {{ $t('printPage.noMore') }}
            </p>
            <!-- 使用base64时 -->
            <!-- <img
              v-for="(item, idx) in tag_svg_list"
              :key="idx"
              :src="item"
              alt=""
              style="width: 100%; margin-bottom: 20px"
              :style="{
                'margin-right': `${
                  (Number(idx) + 1) % print_setting.cols
                    ? tag_canvas_wap_width.space
                    : 0
                }px`,
              }"
            /> -->
            <!-- <template v-if="need_lave">
              <div
                v-for="(svg, index) in lave_svg_list"
                :key="`lave_${index}`"
                style="margin-bottom: 20px"
                class="render_svg_item"
                :style="{
                  'margin-right': `${
                    (Object.keys(tag_svg_list).length + index + 1) %
                    print_setting.cols
                      ? tag_canvas_wap_width.space
                      : 0
                  }px`,
                }"
                v-html="svg"
              ></div>
            </template> -->

            <!-- 使用base64时 -->
            <!-- <img
              v-for="(item, idx) in lave_svg_list"
              :key="idx"
              :src="item"
              alt=""
              style="width: 100%; margin-bottom: 20px"
              :style="{
                'margin-right': `${
                  (Object.keys(tag_svg_list).length + index + 1) %
                  print_setting.cols
                    ? tag_canvas_wap_width.space
                    : 0
                }px`,
              }"
            /> -->
          </div>
        </div>

        <!-- 打印 -->
        <div class="tag_print_box mt16 pl12 pt12 pr12">
          <div class="legend">
            <div class="title bold mb10 fs12 color-0">
              {{ $t('arrangedPage.picSymble') }}
            </div>
            <div class="legend-box">
              <ul>
                <li class="mb10 flex flex-cross--center">
                  <img
                    class="icon"
                    src="@/assets/icon/caret-left-small@2x.png"
                    alt=""
                  />
                  <span class="pl9 color-0">{{
                    $t('printPage.lightSide')
                  }}</span>
                </li>
                <li class="mb10">
                  <span class="pl5 fs14 color-0">{{
                    $t('printPage.front')
                  }}</span>
                  <span class="pl5 pl14 color-0">{{
                    $t('printPage.locFrontSide')
                  }}</span>
                </li>
                <li class="mb17">
                  <span class="open icon fs14 color-0">{{
                    $t('printPage.open')
                  }}</span>
                  <span class="pl9 color-0">{{
                    $t('printPage.openSide')
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="tip fs12 color-o-4" v-if="!inQt">
            {{ $t('printPage.extraTip') }}
          </div>
        </div>
      </div>
    </a-spin>
    <!-- 标签画布 -->
    <canvas
      id="tag"
      style="position: fixed; top: 0; left: 0; z-index: -100; opacity: 1"
    ></canvas>
    <!-- 打印、导出使用画布，为获取canvas宽度，不能使用none -->
    <canvas
      id="print-tag"
      style="position: fixed; top: 0; left: 0; z-index: -100; opacity: 1"
    ></canvas>
    <!-- 导出图片截图使用，不能使用none -->
    <div
      id="tag-image"
      style="position: fixed; top: 0; left: 0; z-index: -100"
    ></div>

    <loading
      :finish_percent="finish_percent"
      :loading="showLoading"
      :isTimeOut="isTimeOut"
      :isWebToQt="isWebToQt"
      :loadingTitle="translateLang('main.sideBar.printTag')"
      type="3"
      :text="
        isPrintFlag && showLoading
          ? translateLang('common.dataLoading')
          : translateLang('printPage.tagDownloading')
      "
    ></loading>
    <g-base-modal
      :visible="isVisible"
      v-if="isVisible"
      @ok="handleDelateTemplate"
      :contain="translateLang('printPage.deleteTip')"
      @cancel="isVisible = false"
    >
    </g-base-modal>
    <!-- 本地直接下载nc文件所使用的目录被删除提示的弹窗 -->
    <g-folder-choose-modal
      :cb="exportImg"
      ref="folderChooseModalRef"
    ></g-folder-choose-modal>
  </div>
</template>

<script>
import { buryPoint } from '@/apis/common/buryPoint'
import { GetProductionNcSetting } from '@/apis/common/productionNcSetting'
import { checkNCDataIntact } from '@/apis/equipment/index'
import { httpRequest } from '@/apis/request'
import { getSpecialRemarks } from '@/apis/tag/index'
import door from '@/assets/labelColor/door.png'
import modular from '@/assets/labelColor/modular.png'
import order from '@/assets/labelColor/order.png'
import room from '@/assets/labelColor/room.png'
import loading from '@/components/common/loading'
import GBaseModal from '@/components/g-base-modal.vue'
import GFolderChooseModal from '@/components/g-folder-choose-modal.vue'
import newContent from '@/components/newContent.vue'
import breadcrumb from '@/components/topBreadCrumb.vue'
import { plankMapState } from '@/constants'
import store from '@/store'
import { updateLocalPreference } from '@/store/modules/preferencesSetting'
import { deepCopy } from '@/util/LayoutFuncs'
import { changeDataStartPoint } from '@/util/NCGenerator'
import { bigPlankTemp } from '@/util/bigPlankTemp'
import { EventBusKey } from '@/util/bus'
import EventBus from '@/util/bus'
import { translate } from '@/util/commonFun'
import {
  buryPointApi,
  canvas2Image,
  compare,
  cvs2image,
  getOriginRect,
  modalMsg,
  splitTime,
} from '@/util/commonFuncs.js'
import { JxTagData, countTagDataByDir } from '@/util/countTagData'
import {
  countSameSizeBoard,
  createLaveTag,
  createTag,
  roomNameToChartMap,
} from '@/util/createTag'
// import html2canvas from 'html2canvas'
import defaultTagData, { defaultPaibanData } from '@/util/defaultTagData'
import { getDeviceXDPI, mmToPx } from '@/util/exportFuncs'
import { convertJpegToBmp } from '@/util/file/convert'
import { savaFileToDisk } from '@/util/fileSystem/generateFile'
import { exportBmp1Bit } from '@/util/jimp'
import JSZip from '@/util/jszip'
import {
  dealFileArrLabel,
  dealFileArrfunc,
} from '@/util/productionDataGenerate/tagDataGenerate'
import { _translateFileNameFn } from '@/util/saveFile'
import { genFirstLevelFileName, getGraghEdge, getSurplusSize } from '@/util/tag'
import { jsPdfTool } from '@/util/tag/pdfFont'
import { uploadFiles as ossUploadFiles } from '@/views/newPaiban/util/uploadFiles'
import bus from '@bus'
import domtoimage from 'dom-to-image'
import FileSaver from 'file-saver'
import { cloneDeep } from 'lodash'
import { mapMutations, mapState } from 'vuex'

import { pxTomm } from '../../util/exportFuncs'
import createPdf from './utils/createPdf'
import { timeSlice_ } from './utils/map-printTag'

// 用于透明度设置防抖
let timer = null
export default {
  name: 'printTag',
  data() {
    return {
      data: [],
      template_list: [],
      active_template_id: -1,
      canvas_scale: 2,
      cur_template: null,
      need_lave: false, // 生成余料标签
      cut_need_lave: true, // 生成裁剪余料标签
      gen_big_plank_tag: false, // 生成大板信息标签
      gen_small_plank_tag: true, // 生成小板标签
      isGenNcSuffix: true, // 大板标签生成下料文件名后缀
      jx_checked: false, // 标签图镜像
      five_six_dir: false, // 五六面钻放板基准
      sort_value: 1, // 标签排序适应自动上下料
      print_color_tag: false, //生成彩色标签
      xCoordinate: 32, // x坐标
      yCoordinate: 20, // y坐标
      saveConfBtnLoading: false,
      file_setting: [],
      file_setting_extra: { img_has_delimiter: false, stock_num_length: 3 },
      file_setting_delimiter: '',
      isVisible: false,
      // all_page: 0,collect
      source_data: [],
      default_sort_source_data: [],
      tag_svg_list: [],
      lave_data: [],
      lave_svg_list: [],
      template_loading: true,
      export_loading: false,
      export_loading_text: translate('printPage.prepareData'),
      // 设置print_setting初始值
      print_setting: {
        cols: 1,
        labelColumnGap: 0,
        opacity: 0.24,
        size: 1,
        sizeAuto: true, //默认勾选特殊标记大小自适应
      },

      showLoading: false,
      timer: (ms) => new Promise((resolve) => setTimeout(resolve, ms)),
      collect: [], // 图片信息收集器
      lave_collect: [],
      // 上一次排版的数据
      afterPaibanData: '',
      // 是否是排版数据变化
      isPaibanDataChange: false,
      testList: [],
      isNoMoreTag: false,
      isTagLoading: false,
      page: 1, // 第几页
      lavePage: 1, // 余料第几页
      limit: 5, // 每页的额个数
      // canvasUrl: '',
      tagListSourceData: [], // 用来生成标签canvas的数据
      laveTagListSourceData: [], // 预料标签生成canvas的数据
      // renderTag: null, // 生成渲染内容的函数
      listDomWrap: null,
      useOld: true,
      useLoadMore: false,
      time: 0,
      isSameTypeSet: false,
      isLoadLavePart: false, // 如果是直接加载全部，有没有这个都无所谓，暂时先保留
      cacheTagList: {}, // 缓存当前的标签
      pdfPageCount: 0, // pdf的页面count，用于导出时的页面loading百分比
      exportType: 'image', // 区分导出的类型，不用的类型百分比的取值来源不同
      isInCurrentRoute: false, // 是否在当前路由，主要为了解决离开当前页面以后，依旧加载的问题

      timeStamp: 0, // 时间戳
      nc_file_settings: {}, // nc文件设置
      ncNameSuffix: 'nc', // nc文件名后缀
      // fileSetting_header_ids: {}, //文件设置的ids
      // needAddNcNamePrefix: false // 是否需要添加nc名前缀
      select_options: [], // 筛选数据
      cut_select_value: 'all',
      cut_progress: 0, //当前进度
      isPrintTagMark: false, // 是否给打印标签加上标记
      inQt: false,
      currentBigpart: null,
      isreloading: false, //是否在重新加载
      isCanDownLoading: false, // 是否可以下载文件
      isShowToolTip: false,
      ncOutputSetting: {}, // NC导出设置
      paibanWayName: `历史方案 ${splitTime()}`,
      ncFileList: [], //nc文件名
      bigplantLableList: [],
      LaveTagBase64List: [],
      isTimeOut: false,
      roomSortWay: '', //板件标记房间排序规则
      isShowFolderTip: false,
      isPrintFlag: false, //是否点击打印
      // 生成彩色标签提示信息遍历数据
      colorTagTips: [
        {
          title: 'common.order',
          imgUrl: order,
          id: 1,
        },
        {
          title: 'common.room',
          imgUrl: room,
          id: 2,
        },
        {
          title: 'common.modular',
          imgUrl: modular,
          id: 3,
        },
        {
          title: 'common.isDoorPlank',
          imgUrl: door,
          id: 4,
        },
      ],
    }
  },
  methods: {
    ...mapMutations([
      'setDrawOpacity',
      'setDrawSize',
      'setDrawSizeAuto',
      'setTempList',
    ]),
    translateLang(key) {
      return translate(key)
    },
    // tooltip鼠标显示
    showTooltip(e) {
      this.isShowToolTip = true
    },
    hideTooltip(e) {
      this.isShowToolTip = false
    },
    // 保存设置
    async saveConf() {
      updateLocalPreference({
        labelTemplate: this.active_template_id,
        surplusLabel: this.need_lave,
        bigPlankLabel: this.gen_big_plank_tag,
        genSmallPlankLabel: this.gen_small_plank_tag,
        isGenNcSuffix: this.isGenNcSuffix,
        mirrorLabel: this.jx_checked,
        boardBenchmark: this.five_six_dir,
        labelSortWay: this.sort_value,
        printColorTag: this.print_color_tag,
        labelColumnNum: this.print_setting.cols,
        labelColumnGap: this.print_setting.labelColumnGap,
        opacity: this.print_setting.opacity,
        size: this.print_setting.size,
        sizeAuto: this.print_setting.sizeAuto,
        cutNeedLave: this.cut_need_lave,
        labelCoord: {
          X: this.xCoordinate,
          Y: this.yCoordinate,
        },
      })
      this.setDrawOpacity(this.print_setting.opacity)
      this.setDrawSize(this.print_setting.size)
      this.setDrawSizeAuto(this.print_setting.sizeAuto)
    },
    async loadLave() {
      this.isNoMoreTag = false
      this.isLoadLavePart = true
      if (this.useLoadMore) {
        this.judgeLoadContinue()
      } else {
        this.loadTagList(false, true)
        await this.waitGenAll()
      }
    },
    /** 生成余料标签 **/
    changeNeedLave() {
      this.$htm_trackEvent('生成余料标签', 'click', this.need_lave)
      this.cut_progress = 0
      this.isTagLoading = false
      // if (this.need_lave && !this.lave_svg_list.length)  this.startRenderLave()
      this.startRender()
      if (this.need_lave) {
        if (this.lave_svg_list.length) {
          // 此时说明标签已经渲染完毕，那么直接把余料标签放到普通标签后面即可
          this.tag_svg_list = [...this.tag_svg_list, ...this.lave_svg_list]
          if (this.tag_svg_list.length < this.renderDataList.length) {
            // 此时说明余料标签还没有加载完，需要继续加载
            this.loadLave()
          } else {
            // 只有预料标签
            this.loadLave()
          }
        } else {
          // 当没有渲染余料标签，并且普通标签已经渲染完毕
          if (this.isNoMoreTag && this.laveTagListSourceData.length) {
            // 如果有余料标签的数据源，那么应该渲染余料标签
            this.loadLave()
          }
        }
      } else {
        if (this.lave_svg_list.length) {
          // 需要把余料的部分给去掉
          this.tag_svg_list = this.tag_svg_list.slice(
            0,
            -this.lave_svg_list.length
          )
          this.isNoMoreTag = true
        }
      }
      this.saveConf()
    },
    /** 筛选改变 */
    siftChange() {
      buryPointApi('tag', 'screen')
      this.cut_progress = 0
      this.tagListSourceData = []
      this.initData()
      this.initLaveData()
      this.timeStamp = Date.now() + ''
      this.startRender()
    },
    /** 生成裁剪板件标签 */
    changeCutNeedLave() {
      this.cut_progress = 0
      this.timeStamp = Date.now() + ''
      this.startRender()
      this.saveConf()
    },
    /** 生成大板标签 */
    changeGenBigPlankTag() {
      this.$htm_trackEvent('生成大板标签', 'click', this.gen_big_plank_tag)
      this.cut_progress = 0
      this.timeStamp = Date.now() + ''
      // 为了避免 loadTagList 里面时间戳判断滞后导致的无法加载的问题
      this.isTagLoading = false
      this.isSameTypeSet = false
      this.startRender()
      this.saveConf()
    },
    /**生成小板标签 */
    changeGenSmallPlankTag() {
      this.$htm_trackEvent('生成小板标签', 'click', this.gen_small_plank_tag)
      this.cut_progress = 0
      this.timeStamp = Date.now() + ''
      this.isTagLoading = false
      this.isSameTypeSet = false
      this.startRender()
      this.saveConf()
    },
    handleXChange() {
      this.saveConf()
    },
    handleYChange() {
      this.saveConf()
    },
    /** 大板标签生成下料文件名后缀 */
    handleChangeNCSuffix() {
      this.cut_progress = 0
      this.timeStamp = Date.now() + ''
      this.startRender()
      this.saveConf()
    },
    /** 标签图镜像 **/
    changeJxChecked() {
      this.$htm_trackEvent('标签图镜像', 'click', this.jx_checked)
      this.cut_progress = 0
      this.timeStamp = Date.now() + ''
      this.source_data = JxTagData(this.source_data)
      this.lave_data = JxTagData(this.lave_data)
      this.default_sort_source_data = JxTagData(this.default_sort_source_data)
      this.isSameTypeSet = false
      this.startRender()
      this.saveConf()
    },
    /** 五六面钻放板基准 */
    changeFiveSixDir() {
      this.cut_progress = 0
      this.timeStamp = Date.now() + ''
      this.startRender()
      this.saveConf()
    },
    /** 标签排序适应自动上下料 **/
    changeSortValue() {
      this.$htm_trackEvent(
        '标签适应上下料',
        'click',
        this.sort_value === 2 ? '向上' : this.sort_value === 3 ? '向下' : '默认'
      )
      this.cut_progress = 0
      this.timeStamp = Date.now() + ''
      this.isSameTypeSet = false
      this.startRender()
      this.saveConf()
    },
    // 重置上下料数据
    resetUpDownMaterialData() {
      this.source_data =
        this.sort_value === 1
          ? this.default_sort_source_data
          : countTagDataByDir(
              this.sort_value,
              this.source_data,
              this.$store.state.ncSetting.xyReverse
            ).tagItemArr
    },
    /** 生成彩色标签 */
    handleChangeColorTag() {
      // 埋点记录
      if (this.print_color_tag) {
        buryPoint({
          function_module: 'tag',
          function_point: 'form_color_label',
        })
      }
      this.cut_progress = 0
      this.timeStamp = Date.now() + ''
      // 重新绘制
      this.startRender()
      this.saveConf()
    },
    /** 获取模板列表 **/
    getTemplateList() {
      return this.$getByToken(
        '/get_tag_setting_list',
        { limit: 100, page: 1 },
        async (res) => {
          if (res.status == 1 && res.data && res.data instanceof Array) {
            this.template_list = [
              { id: 0, name: '默认模板（60×40）' },
              ...res.data,
            ]
            const tagTempList = [
              { id: 0, name: '默认标签模板', data: defaultTagData },
              ...res.data,
            ]
            this.setTempList(tagTempList)
            // 选取默认使用模板
            // let active_template_id = Number(
            //   localStorage.getItem('testNowTagId')
            // )
            // if (!active_template_id && active_template_id !== 0)
            //   active_template_id = Number(
            //     this.$store.state.userInfo.custom_tag_id
            //   )
            // 现在直接使用接口里面的id就行了
            let match_templates = this.template_list.find(
              (template) => template.is_default === 1
            )
            if (match_templates) await this.chooseTemplate(match_templates)
            else await this.chooseTemplate(this.template_list[0])
          } else {
            this.template_list = [{ id: 0, name: '默认模板（60×40）' }]
            await this.chooseTemplate(this.template_list[0])
          }

          this.template_loading = false
          this.isreloading = false
        }
      )
    },
    /** 编辑模板 **/
    editTemplate() {
      buryPointApi('tag', 'editor_tag')
      if (!this.active_template_id) {
        this.$message({ message: '默认标签不可编辑' })
        return
      }
      this.$htm_trackEvent('编辑标签模板', 'click')
      // 标签管理页面进入的添加模板参数，用于活跃菜单的判断
      let from = this.$route.query.from ? 'temp' : undefined
      this.$router.push({
        path: '/editTagTem',
        query: {
          id: this.active_template_id,
          from,
        },
      })
    },
    /** 添加模板 **/
    addTemplate() {
      this.$htm_trackEvent('添加标签模板', 'click')
      buryPointApi('tag', 'add')
      this.$router.push({ path: '/editTagTem' })
    },
    /** 删除模板 **/
    deleteTemplate() {
      this.isVisible = true
      buryPointApi('tag', 'delete')
      if (!this.active_template_id) {
        this.$message({
          duration: 1000,
          message: '默认模板不能删除',
          type: 'warning',
        })
        return
      }
    },
    // 删除模板ftypeName
    handleDelateTemplate() {
      this.$token(
        '/delete_tag_setting',
        { id: this.active_template_id },
        (res) => {
          if (res.status != 1) return
          this.$message({
            duration: 1000,
            message: translate('common.deleteSuccess'),
            type: 'success',
          })
          let index = this.template_list.findIndex(
            (v) => v.id === this.active_template_id
          )
          this.chooseTemplate(this.template_list[index - 1])
          this.template_list.splice(index, 1)
        }
      ).finally(() => {
        this.isVisible = false
      })
    },
    /** 选中模板 **/
    async chooseTemplate(template) {
      localStorage.setItem('testNowTagId', template.id)
      if (template.id === this.active_template_id && !this.isPaibanDataChange)
        return
      this.cut_progress = 0
      this.time = Date.now()
      this.isPaibanDataChange = false
      this.active_template_id = template.id
      // 获取模板详情
      if (!this.active_template_id) {
        // 默认模板（40x60）
        this.cur_template = JSON.parse(defaultTagData)
      } else {
        let self = this
        let getInfo = function (id) {
          return self.$getByToken(
            '/load_tag_setting',
            { id, default: 1 },
            (res) => {
              self.cur_template = res.data.data

              self.cur_template?.tem_data.forEach((item) => {
                if (item.data && item.data.source_data == 'oSize') {
                  item.data = { ...item.data, text: '2440X1220X18' }
                }
                if (item.data && item.data.source_data == 'plankSize') {
                  item.data = { ...item.data, text: '2440*1220*18' }
                }
                if (item.type === 'Typography') {
                  // 将字体大小数据存进store
                  self.$store.commit('setfrontLabelFont', {
                    fontSize: item.data?.textItems[0]?.fontSize,
                    fontPt: item.data?.textItems[0]?.fontPt,
                    fontFamily: item.data?.textItems[0]?.fontFamily,
                    fontWeight: item.data?.textItems[0]?.fontWeight,
                  })
                }
                // 选择了柜体排序（按房间排序的话），此处存储上房间序号的规则
                if (item.type === 'wardrobeNum') {
                  self.roomSortWay = item.data.roomSortWay
                }
              })
              const barCode = self.cur_template.tem_data.find(
                (it) => it.type === 'Onecode' && it.data.code_type === 'EAN13'
              )
              self.isreloading = false
              if (barCode && self.ncSetting.genSimpleLabelBarcode) {
                const cb = self.editTemplate.bind(self)
                modalMsg(
                  translate('printPage.simplifyOneCodeTip'),
                  'warning',
                  translate('common.warning'),
                  cb,
                  {
                    okText: translate('printPage.toEdit'),
                    maskClosable: true,
                    closable: true,
                  }
                )
              }
            }
          )
        }
        await getInfo(template.id)
      }
      this.$store.commit('setCurTemplate', this.cur_template)
      // 重置 tag_svg_list ，防止页面跳动
      this.tag_svg_list = []
      // return
      if (!this.$store.state?.ncSetting?.process_setting_id) {
        this.startRender()
        return
      }
      this.$token(
        '/load_engraving_setting',
        { setting_id: this.$store.state.ncSetting.process_setting_id },
        (res) => {
          this.isreloading = false
          this.ncNameSuffix =
            res.data.ncOutputSetting.fileType.fileNameType.toLowerCase()
          this.ncOutputSetting = res.data.ncOutputSetting

          if (!this.$route.query.from) {
            const [pt1, pt2] =
              res.data.labelingSetting &&
              res.data.labelingSetting.label_resolution
            // 如果有设置分辨率则不采用尺寸
            if (pt1 && pt2) {
              this.cur_template.tag_width = pxTomm(pt1 / 4, getDeviceXDPI())
              this.cur_template.tag_height = pxTomm(pt2 / 4, getDeviceXDPI())
              this.startRender()
              // if (this.need_lave) this.startRenderLave()
            } else {
              this.startRender()
              // if (this.need_lave) this.startRenderLave()
            }
          } else {
            this.startRender()
          }
        }
      )
    },
    /** 获取nc设置 **/
    getFileSetting() {
      let params = {
        process_setting_id:
          this.$store.state.ncSetting.process_setting_id || -1,
        uid: this.$store.state.userInfo.id,
      }
      return this.$token('/get_nc_file_settings', params, (res) => {
        if (res.status != 1) return

        let label_dict = res.data.label_dict
        let nc_file_settings = res.data.nc_file_settings
        this.nc_file_settings = nc_file_settings
        let header_ids = nc_file_settings.customizeSetting.headerIds

        // 如果true，则使用_,这是为了兼容旧数据
        this.file_setting_delimiter =
          nc_file_settings.hasDelimiter === true
            ? '_'
            : nc_file_settings.hasDelimiter
        this.file_setting_extra = {
          stock_num_length: nc_file_settings.stockNumLength,
        }

        let ids = []
        let template_string = nc_file_settings.customizeSetting.templateString
        if (template_string) {
          if (nc_file_settings.hasDelimiter) {
            const spit = nc_file_settings.hasDelimiter
            template_string = template_string.split(spit).map((item) => {
              return item.match(/\{([^)]*)\}/)[1]
            })
          } else {
            template_string = template_string.slice(1, -1)
            template_string = template_string.split('}{')
          }
        } else template_string = []

        template_string.forEach((item) => {
          ids.push(header_ids[item])
        })
        this.file_setting = []
        ids.forEach((id) => {
          let item = label_dict[String(id)]
          let label = '',
            value = ''
          if (item.label_name == 'direction') {
            label = 'front'
            value = nc_file_settings.front
          } else if (item.label_name == 'labelMachineName') {
            label = 'labelMachineName'
            value = nc_file_settings.labelMachineName
          } else if (item.label_name == 'machineCenterName') {
            label = 'labelMachineName'
            value = nc_file_settings.machineCenterName
          } else {
            label = item.label_name
          }
          this.file_setting.push({ label, value })
        })
      })
    },
    showPartSize(part, field, needShowField) {
      const rect = getOriginRect(part, field)
      return (rect?.[needShowField] ?? 0).toFixed(2)
    },
    /** 预处理数据 **/
    initData() {
      const paibanData = JSON.parse(
        JSON.stringify(this.$store.state.paibanData)
      )
      let onePart = null
      if (this.currentBigpart) {
        onePart = this.currentBigpart
      }
      let data = this.currentBigpart ? [onePart] : paibanData
      data = this.selectViewData(data)
      // 生成标签id
      // this.generateLabelNo(data)
      this.afterPaibanData = this.$route.query.from ? '' : JSON.stringify(data)
      if (!data.length) {
        data = defaultPaibanData
      }
      if (this.$route.query.from) {
        data = defaultPaibanData
      }
      // 正反面条码填充值
      let front_file_identifier =
        this.$store.state.ncSetting.front_file_identifier
      let opposite_file_identifier =
        this.$store.state.ncSetting.opposite_file_identifier

      // let len = 0,
      // let parts_index = 1
      // 记录有几个柜子
      let wardrobe_names = {},
        record_wardrobe_num = 0
      data.forEach((item, index) => {
        // 添加可筛选数据
        // item.parts = item.parts.filter(v =>{ return  v.specialType !== 'supplus'; });
        // len += item.parts.length

        item.parts.forEach((part) => {
          part.oriPlankNumF = `${part.plankNum}${opposite_file_identifier}`
          if (front_file_identifier) {
            part.plankNum = `${part.plankNum}${front_file_identifier}`
            part.oriPlankNum = part.plankNum
          }
          let label_img_name = ''
          if (this.$store.state.ncSetting.labelFormat === 'jingmei') {
            label_img_name += `${item.parts[0].address}_`
            this.file_setting.forEach((set) => {
              if (
                set.label === 'front' ||
                set.label === 'labelMachineName' ||
                set.label === 'machineCenterName'
              ) {
                if (set.value) {
                  if (this.file_setting_delimiter)
                    label_img_name += `${set.value}_`
                  else label_img_name += `${set.value}`
                }
              } else {
                if (this.file_setting_delimiter) {
                  if (set.label === 'stockNum') {
                    if (
                      String(index + 1).length <
                      this.file_setting_extra.stock_num_length
                    )
                      label_img_name += `${this.prefixInteger(
                        index + 1,
                        this.file_setting_extra.stock_num_length
                      )}_`
                    else label_img_name += `${index + 1}_`
                  } else label_img_name += `${part[set.label]}_`
                } else {
                  if (set.label === 'stockNum') {
                    if (
                      String(index + 1).length <
                      this.file_setting_extra.stock_num_length
                    )
                      label_img_name += `${this.prefixInteger(
                        index + 1,
                        this.file_setting_extra.stock_num_length
                      )}`
                    else label_img_name += `${index + 1}`
                  } else label_img_name += `${part[set.lable]}`
                }
              }
            })
          }
          part.lableImgName =
            label_img_name +
            (this.ncSetting.genSimpleLabelBarcode
              ? part.simplePlankNum
              : part.oriPlankNum)

          // part.partsIndex = parts_index
          // parts_index++

          if (wardrobe_names.hasOwnProperty(`${part.loc}_${part.roomID}`))
            part.wardrobeNum = wardrobe_names[`${part.loc}_${part.roomID}`]
          else {
            record_wardrobe_num++
            wardrobe_names[`${part.loc}_${part.roomID}`] = record_wardrobe_num
            part.wardrobeNum = wardrobe_names[`${part.loc}_${part.roomID}`]
          }
          // 将宽高恢复成默认顺序
          const oSizeRect = getOriginRect(part)
          const oSizeCutRect = getOriginRect(part, 'realRect')
          if (part.srcTexDir == 'reverse') {
            const copyOSize = JSON.parse(JSON.stringify(oSizeRect))
            const copyOSizeCut = JSON.parse(JSON.stringify(oSizeCutRect))
            oSizeRect.width = copyOSize.height
            oSizeRect.height = copyOSize.width
            oSizeCutRect.width = copyOSizeCut.height
            oSizeCutRect.height = copyOSizeCut.width
          }
          // 定义特殊字段
          // 成品尺寸
          part.oSize = `${(+oSizeRect.height).toFixed(
            2
          )}*${(+oSizeRect.width).toFixed(2)}*${part.thick}`
          // 切割尺寸
          part.cutSize = `${oSizeCutRect.height.toFixed(
            2
          )}*${oSizeCutRect.width.toFixed(2)}*${part.thick}`
          // 材质
          part.matCode_texture = `${part.matCode}-${part.texture}`

          part.handle_name = ''
          if (
            part.handle &&
            part.handle.partsChildren &&
            part.handle.partsChildren.length
          )
            part.handle_name =
              part.handle.partsChildren[0].maskPartName ||
              part.handle.partsChildren[0].matSpec
                ? `${
                    part.handle.partsChildren[0].maskPartName ||
                    part.handle.partsChildren[0].matSpec
                  }_${part.handle.partsChildren[0].name}`
                : ''

          if (Array.isArray(part.partsChildren)) {
            part.partsChildren.forEach((p_c) => {
              if (p_c.name.includes('铰链'))
                part.hinge_label = p_c.maskPartName || p_c.matSpec || ''
              else if (p_c.name.includes('拉直器'))
                part.straightener_label = p_c.maskPartName || p_c.matSpec || ''
            })
          }
        })
      })
      let tagLength = 0
      // this.all_page = len
      for (let i = 0; i < data.length; i++) {
        let plank = data[i]
        plank.parts = plank.parts.sort(compare('priority', 'up', 'priority'))
        tagLength += plank.parts.length
      }
      this.source_data = data
      // 给数据添加auto_index
      this.generateLabelNo(this.source_data)
      this.default_sort_source_data = data
      this.$store.commit('setTagListLength', tagLength)
      if (this.jx_checked) {
        this.source_data = JxTagData(this.source_data)
        this.default_sort_source_data = JxTagData(this.default_sort_source_data)
      }
    },
    prefixInteger(num, n) {
      return (Array(n).join('0') + num).slice(-n)
    },
    /** 生成余料数据 **/
    initLaveData() {
      this.lave_data = []
      // 筛选数据
      const data = this.selectViewData(this.source_data)
      data.forEach((item) => {
        let supplus = [item.parts]
        if (!supplus.length) return
        supplus.forEach((sup) => {
          let panel = {
            texture: item.texture,
            orderNo: sup[0].orderNo,
            roomName: sup[0].roomName,
            matCode: sup[0].matCode,
            parts: sup,
            thick: sup[0].thick,
            plankHeight: item.plankHeight,
            plankWidth: item.plankWidth,
            plankSize: '',
            lableImgName: new Date().getTime(),
          }
          if (sup[0].specialType === 'supplus') {
            let paths = sup[0].surplusPath[0]
            panel.plankSize = getSurplusSize(
              paths,
              this.$store.state.ncSetting,
              sup[0].stockKey
            ).str
          }
          this.lave_data.push(panel)
        })
      })
    },
    // 生成标签序号
    generateLabelNo(data) {
      const start = this.ncSetting?.front_labeling_start_num || '0'
      const _no = Number(start) - 1
      if (data && data.length) {
        data.forEach((item) => {
          item.parts.forEach((it) => {
            it.auto_index = this.generateId(start, _no + it.priority)
          })
        })
      }
    },
    generateId(initStart, id) {
      let _id = id + ''
      if (_id.length < initStart.length) {
        _id = _id.padStart(initStart.length, 0)
      }
      return _id
    },
    // 刷新tagList要渲染的数据来源
    refreshTagListSourceData() {
      if (this.tagListSourceData.length && this.isSameTypeSet) {
        return
      }
      this.tagListSourceData = []
      let bigTagList = []
      for (let index = 0; index < this.source_data.length; index++) {
        const plank = this.source_data[index]
        let parts = this.source_data[index].parts

        const { size, graghEdge } = getGraghEdge(plank, this.ncSetting)

        let ncName = this.file_setting
          .map((item) => {
            if (item.value) {
              return item.value
            } else {
              const stockNumLength = this.nc_file_settings.stockNumLength
              if (item.label === 'stockNum' && stockNumLength) {
                let value = index + 1 + '' // 序号用第几块板，如果不对，就把this.source_data用stockNum进行排序
                return value.padStart(stockNumLength, '0')
              } else if (item.label === 'front') {
                return this.nc_file_settings.front || parts[0].front || '正面'
              } else {
                return parts[0][item.label]
              }
            }
          })
          .join(this.file_setting_delimiter)
        let headersIds = this.nc_file_settings.customizeSetting?.headerIds

        // 如果没有大板序号，手动加上
        if (!headersIds['大板序号']) {
          ncName = index + 1 + this.file_setting_delimiter + ncName
        }

        // nc导出设置里选择了正反面且开启了正反面合并，去掉正反面相关字段
        if (
          headersIds['正面/反面'] &&
          this.ncOutputSetting?.fileMerge?.doubleEdgeCombine
        ) {
          let frontStr = this.ncOutputSetting.fileNameSetting.front_info
            ? this.ncOutputSetting.fileNameSetting.front_info
            : '正面'
          let backStr = this.ncOutputSetting.fileNameSetting.back_info
            ? this.ncOutputSetting.fileNameSetting.back_info
            : '反面'
          const reg = new RegExp(
            `\\${this.file_setting_delimiter}${frontStr}|\\${this.file_setting_delimiter}${backStr}`,
            'gi'
          )
          ncName = ncName.replace(reg, '')
        }
        // 给ncName加上后缀
        ncName = `${ncName}.${this.ncNameSuffix}`
        let currentPageNum = 1
        if (this.cut_need_lave) {
          currentPageNum = this.tagListSourceData.length + 1
        } else {
          currentPageNum =
            this.tagListSourceData.filter(
              (item) => !item.part.specialType && item.type !== 'plank'
            ).length +
            index +
            1
        }
        if (this.gen_big_plank_tag) {
          // 大板信息
          let plankObj = {
            plank,
            plank_index: index + 1, // 第几张大板
            part: {
              ...parts[0],
              page: currentPageNum, //第几页
            },
            type: 'plank',
          }
          // 大板绘制信息
          const normalPart = plank.parts.find((part) => !part.specialType)
          const plankDrawMsg = {
            programAddress: normalPart
              ? normalPart.address
              : plank.parts[0].address,
            orderNo: normalPart ? normalPart.orderNo : plank.parts[0].orderNo,
            material: plank.thick + plank.matCode + plank.texture,
            size,
            ncName,
            qrCode: ncName,
            plank_index: `第${plankObj.plank_index}张大板`,
            lable_index: ``,
          }
          // 把 plankDrawMsg、graghEdge 放到 plank 里面
          plankObj.plank = {
            ...plankObj.plank,
            plankDrawMsg,
            graghEdge,
          }
          // plankObj.plankDrawMsg = plankDrawMsg
          // plankObj.graghEdge = graghEdge
          this.tagListSourceData.push(plankObj)
          bigTagList.push(plankObj)
        }
        if (this.gen_small_plank_tag || this.cut_need_lave) {
          let newParts = parts
          // 裁剪余料标签
          const cutLaveTags = parts.filter(
            (item) => item.specialType === 'supplus'
          )
          if (!this.gen_small_plank_tag && this.cut_need_lave)
            newParts = cutLaveTags
          for (let p_index = 0; p_index < newParts.length; p_index++) {
            if (!newParts[p_index].surplusInfo) {
              newParts[p_index].surplusInfo = plank?.surplusInfo
            }
            let obj = {
              plank: {
                ...this.source_data[index],
                graghEdge,
              },
              part: {
                ...(JSON.parse(JSON.stringify(newParts[p_index])) || {}),
                page: this.tagListSourceData.length + 1,
                ncName,
              },
              plank_index: index + 1,
              part_index: p_index,
              currentPlank: newParts[p_index],
              type: 'tag',
            }
            this.tagListSourceData.push(obj)
          }
        }
      }
      if (bigTagList.length) {
        // 修改每个属性的label_index信息
        this.$nextTick(() => {
          bigTagList.forEach((item) => {
            item.plank.plankDrawMsg.lable_index = `第${item.part.page}/${this.all_page}页`
          })
        })
      }
    },
    // 刷新预料标签要渲染的数据来源
    refreshLaveTagSourceData() {
      if (this.laveTagListSourceData.length && this.isSameTypeSet) {
        return
      }
      this.laveTagListSourceData = []
      for (let index = 0; index < this.lave_data.length; index++) {
        let obj = {
          plank: this.lave_data[index],
          plank_index: index + 1,
          type: 'laveTag',
        }
        this.laveTagListSourceData.push(obj)
      }
    },
    /** 开始渲染 **/
    async startRender() {
      // 根据上下料选项重置数据
      this.resetUpDownMaterialData()
      this.refreshTagListSourceData()
      this.refreshLaveTagSourceData()
      // 重新渲染重置，会一直积累
      this.collect = []
      this.loadTagList(true, this.useLoadMore ? false : true)
    },
    /**
     * needTranslate: 是否需要转换成svg或者图片
     */
    async preparePrintData(
      tag_type = 'base64',
      scale = 1,
      needTranslate = true,
      paibanData = this.paibanData
    ) {
      let print_data = [],
        print_data_qt = []
      const separator = this.$store.state.ncSetting.label_image_name_separator
      this.showLoading = true
      let url = ''
      // if (needTranslate) {
      // url = await createTag(
      //   this.cur_template,
      //   this.source_data[index],
      //   JSON.parse(JSON.stringify(parts[p_index])),
      //   index + 1,
      //   p_index,
      //   this.all_page,
      //   this.jx_checked,
      //   this.five_six_dir,
      //   { scale: scale, canvas: 'print-tag', type: tag_type },
      //   this.$store.state.ncSetting,
      //   parts[p_index]
      // )
      // }
      let ncFileList = []
      for (let index = 0; index < this.tagListSourceData.length; index++) {
        let item = this.tagListSourceData[index]
        const ncCustomLabel = this.ncSetting.label_image_name_type ?? []
        let ncFileName = ''
        let labelFileName = ''
        let ncAutoIndex = ''
        if (
          (ncCustomLabel.some((type) =>
            ['labeling_filename', 'nc_filename', 'auto_index'].includes(type)
          ) ||
            this.ncSetting.label_img_big_plank_folder) &&
          !ncFileList.length
        ) {
          // 对小板类的切割顺序按照升序排列
          let paibanDataCopy = deepCopy(this.paibanData)
          this.generateLabelNo(paibanDataCopy)
          paibanDataCopy.forEach((item) => {
            item.parts.sort((a, b) => a.priority - b.priority)
          })
          const nc = await httpRequest.post('new_nc', {
            uid: this.userInfo.id,
            data: JSON.stringify(changeDataStartPoint(paibanDataCopy)),
            id:
              this.ncSetting.process_setting_name == '默认生产线'
                ? -1
                : this.ncSetting.process_setting_id,
            keepKnifeOrigin: true,
            from: 'web',
            embedded: sessionStorage.getItem('embedded') ? 1 : 0,
            guigui_version: sessionStorage.getItem('guigui_version'),
            version: 'v1',
            longPlankCuttingSetting: this.ncSetting.longPlankCuttingSetting,
          })
          const params = {
            file_list: nc.data,
            process_id:
              this.ncSetting.process_setting_name == '默认生产线'
                ? -1
                : this.ncSetting.process_setting_id,
            uid: this.userInfo.id,
          }
          checkNCDataIntact(params).then((res) => {
            if (res.data.status !== 1) {
              this.$message.error(res.data.msg)
            }
          })
          if (nc?.data?.knife_error || nc.status === 0) {
            this.showLoading = false
            this.isPrintFlag = false
            this.export_loading = false
            this.$confirm(`${nc.msg}`, '警告', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'error',
            })
            throw '找不到刀报错'
          }
          ncFileList = nc.data
        }
        ncFileName = dealFileArrfunc(item.plank, ncFileList, 'nc')
        labelFileName = dealFileArrfunc(item.plank, ncFileList, 'label')
        ncAutoIndex = dealFileArrLabel(item.part, ncFileList)
        const fileNameMap = new Map([
          ['address', item.part['address']],
          ['orderNo', item.part['orderNo']],
          ['nc_filename', ncFileName],
          ['labeling_filename', labelFileName],
          [
            'plankNum',
            this.ncSetting.genSimpleLabelBarcode
              ? item.part.simplePlankNum
              : item.part['plankNum'],
          ],
          ['auto_index', ncAutoIndex],
          ['stockNum', item.plank_index],
          ['priority', item.part['priority']],
          ['labelId', item.part['labelId']],
        ])
        let fileName = ''
        if (item.type === 'plank') {
          fileName = `${item.plank_index}`
        } else {
          fileName = ncCustomLabel.length
            ? ncCustomLabel.map((type) => fileNameMap.get(type)).join(separator)
            : `${item.part.lableImgName}${separator}${item.plank_index}${separator}${item.part['priority']}`
        }
        // fileName = `${parts[p_index].lableImgName}${separator}${
        //     index + 1
        //   }${separator}${parts[p_index]['priority']}`
        url = this.collect[index]
        print_data.push({ name: fileName })
        if (tag_type !== 'svg') {
          print_data_qt.push({
            name: fileName,
            url: needTranslate
              ? url.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
              : '',
          })
        }
      }
      this.ncFileList = ncFileList
      this.showLoading = false
      this.export_loading = false
      this.export_loading_text = '导出图片'
      // 获取板件是否需要逆时针旋转
      let isRotatePlank = false
      const typographyKlass = this.cur_template.tem_data.find(
        (it) => it.type === 'Typography'
      )
      if (typographyKlass) {
        isRotatePlank = typographyKlass.data.plankRotate
      }
      if (this.need_lave)
        for (let index = 0; index < this.lave_data.length; index++) {
          let url = ''
          if (needTranslate) {
            url = await createLaveTag(
              this.cur_template,
              this.lave_data[index],
              index + 1,
              { scale: scale, canvas: 'print-tag', type: tag_type },
              this.$store.state.ncSetting,
              false,
              isRotatePlank,
              this.all_page,
              this.part_page,
              index
            )
          }
          const fileName = `${this.lave_data[index].lableImgName}${separator}${
            index + 1
          }`
          print_data.push({ name: fileName, url })
          if (tag_type !== 'svg')
            print_data_qt.push({
              name: fileName,
              url: needTranslate
                ? url.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
                : '',
            })
        }

      let template_name = this.template_list.filter((v) => {
        return v.id === this.active_template_id
      })[0].name

      return { print_data, print_data_qt, template_name }
    },
    // 生成所有的渲染taq
    async genAllRenderTag() {
      this.showLoading = true
      // this.isPrintFlag = true
      await this.loadTagList(false, true)
      this.showLoading = false
      this.isPrintFlag = false
      this.export_loading = false
      this.export_loading_text = translate('common.exportPic')
    },
    printPre() {
      buryPointApi('tag', 'print')
      if (this.inQt) {
        // 如果在qt就直接执行print()方法
        this.print()
        return
      }
      // 只提示一次
      let printFlag = localStorage.getItem('printFlag')
      this.isPrintFlag = true
      if (printFlag) {
        this.print()
        return
      }
      localStorage.setItem('printFlag', true)
      // this.$confirm(
      //   '首次使用直接打印时，请在打印机设置中新增一张使用的该尺寸且长、宽对调的纸张，然后使用新增纸张进行打印  （例：原纸张尺寸60X40，新增纸尺寸40X60）',
      //   '提示',
      //   {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     // type: 'warning',
      //   }
      // )
      //   .then(() => {
      //     dom.style.display = 'none'
      //     this.print()
      //   })
      //   .catch(() => {
      //   })
      // 首次打印提示
      this.$antdConfirm({
        title: this.translateLang('common.tip'),
        content: this.translateLang('printPage.firstPrintTip'),
        okText: this.translateLang('common.confirm'),
        cancelText: this.translateLang('common.cancel'),
        centered: true,
        // icon: () => createVNode(ExclamationCircleOutlined),
        icon: 'exclamation-circle',
        onOk: () => {
          const dom = document.querySelector('.ant-modal-confirm')
          dom.style.display = 'none'
          this.print()
        },
        onCancel: () => {},
      })
    },
    /** 打印 **/
    async print() {
      this.exportType = 'print'
      let hasGenAllCollect = this.need_lave
        ? this.collect.length === this.renderDataList.length
        : this.collect.length > this.tagListSourceData.length

      if (!hasGenAllCollect) {
        await this.genAllRenderTag()
      }

      this.$htm_trackEvent('直接打印标签', 'click')
      this.export_loading = true
      this.export_loading_text = '准备数据中...'
      this.isPrintFlag = true
      this.showLoading = true
      this.export_loading = false
      let print_data = await this.preparePrintData()
      !this.isSubPrintTag && this.autoSaveHistory()
      // return
      if (typeof window.webToQt !== 'undefined') {
        webToQt.webLabelPdfToQtV2(
          {
            fileName: `${print_data.template_name}.pdf`,
            imgArr: print_data.print_data_qt.map((v) => {
              return v.url
            }),
            tagWidth: this.cur_template.tag_width,
            tagHeight: this.cur_template.tag_height,
          },
          'print'
        )
        this.showLoading = false
        this.isPrintFlag = false

        return
      }
      if (typeof window.webToQt !== 'undefined') {
        this.derivePdf()
        this.showLoading = false
        this.isPrintFlag = false

        return
      }
      let isPrintTags = [
        ...this.tag_svg_list,
        ...Object.values(this.lave_svg_list),
      ]
      this.$store.commit('savePrintData', {
        data: isPrintTags.map((v) => {
          return {
            width: this.cur_template.tag_width,
            height: this.cur_template.tag_height,
            url: v,
          }
        }),
        setting: this.print_setting,
      })
      let collect = this.need_lave
        ? this.collect
        : this.collect.slice(0, this.tagListSourceData.length)
      this.$store.commit('saveCollect', collect)
      const dpi = getDeviceXDPI()
      // 获取模板标签对应的像素宽度
      const templateWidthPx = mmToPx(this.cur_template.tag_width, dpi)
      const tagDom = document.querySelector('.render_svg_item')
      // 标签对应的宽度
      const tagWidth = tagDom.offsetWidth
      // 这个比例是标签转换为模板大小实际要进行的缩放
      const tagScale = templateWidthPx / tagWidth
      this.$store.commit('setTagScale', tagScale)

      // 给打印标签加上标记（方便在打印页面设置样式）
      this.isPrintTagMark = true
      // 保存标签列表对应的dom
      let tagListDom = document.querySelector('.print_tag_list')
      let tagListDomString = tagListDom.innerHTML
      this.$store.commit('savetagListDomString', tagListDomString)
      this.showLoading = false
      this.isPrintFlag = false

      // 去掉标记
      this.isPrintTagMark = false
      this.$router.push({
        path: '/print',
        query: { scale: this.canvas_scale },
      })
    },
    /** 导出pdf **/
    async exportPdf() {
      buryPointApi('tag', 'export_pdf')
      this.exportType = 'pdf'
      this.pdfPageCount = 0
      // const QtFrom = sessionStorage.getItem('QtFrom')
      this.$htm_trackEvent('导出标签pdf', 'click')
      this.export_loading = true
      this.export_loading_text = `${translate('common.exportPDF')}...`
      setTimeout(() => {
        this.isTimeOut = true
      }, 20000)
      // 全局排版导出pdf自动保存历史
      !this.isSubPrintTag && this.autoSaveHistory()
      /** 创建pdf实例 */
      // if(QtFrom === 'guigui') {
      //   let print_data = await this.preparePrintData();
      //   if (typeof window.webToQt !== 'undefined') {
      //     this.export_loading = false;
      //     webToQt.webLabelPdfToQtV2({
      //       fileName: `${print_data.template_name}.pdf`,
      //       imgArr: print_data.print_data_qt.map(v=> { return v.url; }),
      //       tagWidth: this.cur_template.tag_width,
      //       tagHeight: this.cur_template.tag_height,
      //     }, 'pdf');
      //     return;
      //   }
      // }
      // 新版guigui的下载走pdf绘制方式
      // if(QtFrom === 'guigui2') {
      //   if (typeof window.webToQt !== 'undefined') {
      //     this.export_loading = false;
      //     webToQt.webLabelPdfToQtV2({
      //       fileName: `${template_name}.pdf`,
      //       imgArr: [QTBase64Url],
      //       tagWidth: this.cur_template.tag_width,
      //       tagHeight: this.cur_template.tag_height,
      //       }, 'pdf');
      //       return;
      //   }
      // }
      this.derivePdf()
      this.isTimeOut = false
      this.export_loading = false
    },
    // 等待生成完毕
    async waitGenAll() {
      // this.showLoading = true
      return new Promise((resolve) => {
        bus.$on('waitGenAll', () => {
          resolve()
          // this.showLoading = false
          // this.isPrintFlag = false
          bus.$off('waitGenAll')
        })
      })
    },
    /** 导出图片 **/
    async exportImg() {
      // 检查本地所使用的目录是否存在
      if (!(await this.$refs.folderChooseModalRef?.verify())) {
        return
      }
      // 按钮禁用
      if (
        this.print_color_tag &&
        this.$store.state.ncSetting.labelImageFormatNew == 'bmp' &&
        this.$store.state.ncSetting.bmp_color_style == 1
      ) {
        return
      }
      // 检测标签图片是否按大板分不同文件夹时 是否勾选了小板标签
      let nc_setting
      if (this.ncSetting?.process_setting_id) {
        const nc_setting_res = await GetProductionNcSetting({
          setting_id: this.ncSetting?.process_setting_id,
        })
        if (nc_setting_res.data) nc_setting = nc_setting_res.data
      }
      const { label_img_big_plank_folder, isMergeFile } =
        nc_setting || this.ncSetting
      if (
        label_img_big_plank_folder &&
        !isMergeFile &&
        !this.gen_small_plank_tag
      ) {
        this.$message.warning(this.$t('common.downImageErrTip'))
        return
      }
      buryPointApi('tag', 'export_img')
      this.exportType = 'image'
      this.isTimeOut = true
      await this.timer(20)
      const time = Date.now()
      // 一级文件名
      let firstLevelFileName = ''
      let originFileNameData = {}
      try {
        let obj = await genFirstLevelFileName(true)
        firstLevelFileName = obj.firstLevelFileName
        originFileNameData = obj.data
      } catch (error) {}
      // 因为collect可能会收集部分的余料，所以做如下判断
      let hasGenAllCollect = this.need_lave
        ? this.collect.length === this.renderDataList.length
        : this.collect.length >= this.tagListSourceData.length
      if (!hasGenAllCollect) {
        if (this.useLoadMore) {
          await this.genAllRenderTag()
        } else {
          this.showLoading = true
          await this.waitGenAll()
          this.showLoading = false
          this.export_loading = false
          this.export_loading_text = '导出图片'
        }
      }
      // 根据need_lave得到最终的图片列表
      let collect = this.need_lave
        ? this.collect
        : this.collect.slice(0, this.tagListSourceData.length)
      this.$htm_trackEvent('导出标签图片', 'click')
      this.export_loading = true
      this.export_loading_text = '导出图片中...'
      // let time2 = Date.now();

      // 生成svg所需要的时间
      // let print_data = await this.preparePrintData("base64", 3);
      // 这儿主要用到里面的一些名字信息

      const data = this.currentBigpart ? [this.currentBigpart] : this.paibanData
      let print_data = await this.preparePrintData('svg', 3, false, data)
      const imgFileName =
        originFileNameData.label_image_folder || print_data.template_name
      // 创建图片转bmp实例
      const labelImage = this.$store.state.ncSetting.labelImageFormatNew
      const bmp_color_style = this.$store.state.ncSetting?.bmp_color_style
      // 内嵌云排版执行
      if (typeof window.webToQt !== 'undefined') {
        this.export_loading = false
        this.showLoading = false
        this.isPrintFlag = false
        // let originFileName = firstLevelFileName
        //   ? `${firstLevelFileName}/${imgFileName}`
        //   : print_data.template_name
        // let originFileName = imgFileName || print_data.template_name
        // 在此处判断贴标图片文件是否需要生成文件夹

        let originFileName = ''
        if (
          this.$store.state.ncSetting.customizeFolder?.required_folders?.indexOf(
            'label_image_folder'
          ) !== -1
        ) {
          originFileName = imgFileName || '贴标图片'
        }
        const newConllect = []
        if (labelImage && labelImage === 'bmp' && bmp_color_style == 1) {
          for (let i = 0; i < collect.length; i++) {
            const element = collect[i]
            const re = await exportBmp1Bit(element)
            newConllect.push(re)
          }
        }
        window.webToQt.webLabelImgToQt({
          tagWidth: this.cur_template.tag_width,
          tagHeight: this.cur_template.tag_height,
          imgArr: print_data.print_data.map((v, index) => {
            return {
              url:
                labelImage && labelImage === 'bmp' && bmp_color_style == 1
                  ? newConllect[index].split(',')[1]
                  : collect[index].split(',')[1],
              fileName: `${v.name}.${
                this.$store.state.ncSetting.labelImageFormat || 'jpg'
              }`,
            }
          }),
          originFileName: `${originFileName}`,
        })
        return
      }

      let zip = new JSZip()
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let self = this
      let currentZip = zip
      // 收集的bmp图片数组
      const bmpList = []
      if (labelImage && labelImage === 'bmp') {
        for (let i = 0; i < collect.length; i++) {
          let item = collect[i]

          if (this.ncSetting.bmp_color_style) {
            const newBase64 = await exportBmp1Bit(item)
            item = newBase64
            bmpList.push(item)
          } else {
            const imgUrl = await convertJpegToBmp.convertJpegToBmp(item)
            bmpList.push(imgUrl)
          }
        }
      }
      if (bmpList.length) {
        collect = bmpList
      }
      const _ncFileList = this.ncFileList.filter(
        (i) => i.filetype === 'nc' && i.direction === 'front'
      )
      let idx = 0
      if (label_img_big_plank_folder && !isMergeFile) {
        let folders
        if (
          this.$store.state.ncSetting.customizeFolder.required_folders?.indexOf(
            'label_image_folder'
          ) !== -1
        ) {
          folders = zip.folder(imgFileName || '贴标图片')
        }
        for (let index = 0; index < this.source_data.length; index++) {
          const item = this.source_data[index]
          const idy = _ncFileList.findIndex(
            (i) => i.stock_key === item.stockKey
          )
          const dirname = _ncFileList[idy].file_display_name.slice(0, -3)
          if (firstLevelFileName) {
            currentZip = (folders || zip).folder(`${dirname}`)
          }
          const labelImageFormatNew =
            this.$store.state.ncSetting.labelImageFormatNew
          // 没有勾选生成余料的情况过滤掉多的标签信息
          if (!this.cut_need_lave) {
            item.parts = item.parts.filter((i) => i.specialType !== 'supplus')
          }
          // 生成的每个文件夹中图片的数量
          let list = []
          if (this.need_lave && this.gen_big_plank_tag) {
            list = [
              this.bigplantLableList[index],
              ...item.parts,
              this.LaveTagBase64List[index],
            ]
          } else if (this.need_lave && !this.gen_big_plank_tag) {
            list = [...item.parts, this.LaveTagBase64List[index]]
          } else if (!this.need_lave && this.gen_big_plank_tag) {
            list = [this.bigplantLableList[index], ...item.parts]
          } else {
            list = [...item.parts]
          }
          // 是bmp图片格式
          if (labelImageFormatNew && labelImageFormatNew === 'bmp') {
            for (let indey = 0; indey < list.length; indey++) {
              const itey = list[indey]
              let it = collect[idx].split(',')[1]
              let filename = print_data.print_data[idx].name

              if (this.ncSetting.bmp_color_style) {
                let base64Str = collect[idx]
                if (indey === list.length - 1 && this.need_lave) {
                  // 当前文件夹余料标签的索引 = 图片列表的长度-文件夹的数量+当前生成的第几个文件夹
                  const currentIndex =
                    collect.length - this.source_data.length + index
                  base64Str = collect[currentIndex]
                  filename = print_data.print_data[currentIndex].name
                  idx--
                }
                it = base64Str.split(',')[1]
              } else {
                if (indey === list.length - 1 && this.need_lave) {
                  // 当前文件夹余料标签的索引 = 图片列表的长度-文件夹的数量+当前生成的第几个文件夹
                  const currentIndex =
                    collect.length - this.source_data.length + index
                  it = collect[currentIndex].split(',')[1] // 拿到的是余料标签
                  filename = print_data.print_data[currentIndex].name
                  idx--
                }
              }

              currentZip.file(
                `${filename}.${
                  self.$store.state.ncSetting.labelImageFormat || 'jpg'
                }`,
                it,
                { base64: true }
              )
              idx++
            }
          } else {
            list.forEach((itey, indey) => {
              let it = collect[idx].split(',')[1]
              let filename = print_data.print_data[idx].name
              if (indey === list.length - 1 && this.need_lave) {
                // 当前文件夹余料标签的索引 = 图片列表的长度-文件夹的数量+当前生成的第几个文件夹
                const currentIndex =
                  collect.length - this.source_data.length + index
                it = collect[currentIndex].split(',')[1] // 获取到是对应的余料标签
                filename = print_data.print_data[currentIndex].name
                idx--
              }
              currentZip.file(
                `${filename}.${
                  self.$store.state.ncSetting.labelImageFormat || 'jpg'
                }`,
                it,
                { base64: true }
              )
              idx++
            })
          }
        }
      } else {
        if (
          this.$store.state.ncSetting?.customizeFolder?.required_folders?.indexOf(
            'label_image_folder'
          ) !== -1
        ) {
          currentZip = zip.folder(imgFileName || '贴标图片')
        }
        // 新版bmp格式
        const labelImageFormatNew =
          this.$store.state.ncSetting.labelImageFormatNew
        if (labelImageFormatNew && labelImageFormatNew === 'bmp') {
          for (let index = 0; index < collect.length; index++) {
            let url = collect[index].split(',')[1]
            // TODO 是否为位深1生成图片
            if (this.ncSetting.bmp_color_style) {
              const newBase64 = await exportBmp1Bit(collect[index])
              url = newBase64.split(',')[1]
            }
            currentZip.file(
              `${print_data.print_data[index].name}.${'bmp'}`,
              url,
              { base64: true }
            )
          }
        } else {
          collect.forEach((item, index) => {
            item = item.split(',')[1]
            currentZip.file(
              `${print_data.print_data[index].name}.${
                self.$store.state.ncSetting.labelImageFormat || 'jpg'
              }`,
              item,
              { base64: true }
            )
          })
        }
      }
      // 生成压缩包
      zip.generateAsync({ type: 'blob' }).then(async (content) => {
        self.export_loading = false
        const name = firstLevelFileName
          ? firstLevelFileName
          : print_data.template_name
        let flag = false
        // 是否采用zip的形式导出 否则是采用文件直接写入
        if (
          this.$store.state.preferencesSetting.setting?.is_use_folder_export
        ) {
          flag = await savaFileToDisk(zip, name || '标签图片')
        }
        if (!flag) {
          FileSaver.saveAs(content, `${name}.zip`)
        }
      })
      // 全局排版导出导出图片自动保存历史
      this.showLoading = true
      !this.isSubPrintTag && this.autoSaveHistory()
      this.$message.success(translate('common.downloadDoneByType'))
      this.isTimeOut = false
      this.showLoading = false
      this.isPrintFlag = false
      this.export_loading = false
      this.export_loading_text = translate('common.exportPic')
    },
    /** 截取标签 **/
    tagScreenshot(dom) {
      return new Promise((resolve, reject) => {
        // let dom = document.getElementById("tag-image");
        //   setTimeout(() => {
        //     html2canvas(dom, {
        //       allowTaint: true,
        //       useCORS: true
        //     })
        //       .then(canvas => {
        //         resolve(canvas.toDataURL("image/png", 1));
        //       })
        //       .catch(reject());
        //   });
        // });
        domtoimage.toPng(dom).then((dataUrl) => {
          resolve(dataUrl)
        })
        // return html2canvas(dom, {
        //   allowTaint: true,
        //   useCORS: true
        // }).then(canvas => {
        //   return canvas.toDataURL("image/png", 1);
        // });
      })
    },
    async derivePdf() {
      this.showLoading = true
      const isTemp = this.$route.query.from ? true : false
      /** pdf下载 */
      let template_name = this.template_list.filter((v) => {
        return v.id === this.active_template_id
      })[0].name
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this
      // 加载字体
      await jsPdfTool.loadFontByTagTemp([
        ...(this.cur_template.tem_data ?? []),
        this.cur_template.surplus_data ?? [],
      ])
      let downloadPdf = new createPdf(this.cur_template, function () {
        that.pdfPageCount++
      })
      let lave_data = this.need_lave ? this.lave_data : []
      /** 调用pdf生成方法 1 */
      // await downloadPdf.create(this.source_data, lave_data, { scale:this.canvas_scale }, template_name)
      // downloadPdf.savePdf(template_name)
      /** 调用pdf生成方法 2*/
      // 对roomNameMap进行深拷贝，因为在生成标签过程中要对该值进行删除，为了不影响原始值，同时为了性能，因此在循环外进行此操作
      let copyRoomNameMap = null
      if (this.roomNameMap) {
        copyRoomNameMap = cloneDeep(this.roomNameMap)
      }
      const fun = timeSlice_(downloadPdf.create)
      // await fun(
      //   this.source_data,
      //   lave_data,
      //   {},
      //   this.five_six_dir,
      //   downloadPdf.getInstance(),
      //   this.sort_value,
      //   isTemp,
      //   this.$store.state.ncSetting
      // )
      // 获取板件是否需要逆时针旋转
      let isRotatePlank = false
      const typographyKlass = this.cur_template.tem_data.find(
        (it) => it.type === 'Typography'
      )
      if (typographyKlass) {
        isRotatePlank = typographyKlass.data.plankRotate
      }
      this.tagListSourceData = this.tagListSourceData.map((item) => ({
        ...item,
        plank: { ...item.plank, isGenNcSuffix: this.isGenNcSuffix },
      }))
      let judgeLimit = this.handleJudgeLimit('pdf')
      const statisticsCount = countSameSizeBoard(
        this.renderDataList.filter((item) => item.type === 'tag')
      )
      await fun(
        this.source_data,
        this.tagListSourceData,
        lave_data,
        {
          roomNameMap: copyRoomNameMap,
          print_color_tag: this.print_color_tag,
          judgeLimit,
          statisticsCount,
        },
        this.five_six_dir,
        downloadPdf.getInstance(),
        this.sort_value,
        isTemp,
        this.$store.state.ncSetting,
        this.cut_need_lave,
        isRotatePlank,
        this.jx_checked
      )

      downloadPdf.savePdf(template_name)

      // 一级文件名
      // let firstLevelFileName = ''
      // try {
      //   let obj = await genFirstLevelFileName(true)
      //   firstLevelFileName = obj.firstLevelFileName
      // } catch (error) {
      // }

      // let zip = new JSZip()
      // let currentZip = zip
      // if (firstLevelFileName) {
      //   currentZip = zip.folder(firstLevelFileName)
      // }
      // currentZip.file(`${template_name}.pdf`, downloadPdf.toBase64(), {
      //   base64: true,
      // })

      // zip.generateAsync({ type: 'blob' }).then((content) => {
      //   self.export_loading = false
      //   FileSaver.saveAs(content, `${firstLevelFileName}.zip`)
      // })

      this.isPrintFlag = false
      this.showLoading = false
      this.export_loading = false
      this.export_loading_text = translate('common.exportPic')
    },
    resetGenBigTag() {
      this.gen_big_plank_tag = false
    },
    resetLavePart(keepLaveStatus) {
      if (!keepLaveStatus) {
        this.need_lave = false
      }
      this.isLoadLavePart = false
      this.lavePage = 1
      this.lave_svg_list = []
    },
    // 重置缓存
    resetCache() {
      this.cacheTagList = {}
    },
    // 重置数据
    async resetRequest() {
      // 将分类数据传入筛选数组
      // 初始化可筛选数据
      this.select_options = [{ label: translate('common.all'), value: 'all' }]
      const paibanData = JSON.parse(
        JSON.stringify(this.$store.state.paibanData)
      )
      let onePart = this.currentBigpart

      let data = onePart ? [onePart] : paibanData
      data.forEach((item) => {
        if (item.stockKey) {
          const stockKey = item.stockKey.split(':').slice(0, 3).join(':')
          const f = this.select_options.find((it) => it.value === stockKey)
          if (!f) {
            this.select_options.push({ label: stockKey, value: stockKey })
          }
        }
      })
      // await this.getPreferencesSetting(undefined, this.userInfo.platform)
      // if (this.$store.state.ncSetting.labelFormat === 'jingmei')
      await this.getFileSetting()
      // this.print_setting = { cols: 1, col_space: 0 }
      // this.sort_value = 1
      this.resetLavePart(true)
      this.resetCache()
      // this.resetGenBigTag()
      // this.jx_checked = false
      // this.five_six_dir = false
      this.initData()
      this.initLaveData()
      this.tagListSourceData = []
      this.laveTagListSourceData = []
      this.getTemplateList()
      getSpecialRemarks().then((res) => {
        if (res.status === 200) {
          this.$store.commit('setSpecialRemarks', res?.data?.data ?? {})
        }
      })
    },

    // 加载标签了列表
    async loadTagList(isRefresh, isLoadAll) {
      // 获取最新的ncSetting 直接进入标签管理是没有process_setting_id的，后端会报错
      // 需要进行判断
      if (this.$store.state.ncSetting?.process_setting_id) {
        let ncParams = {
          setting_id: this.$store.state.ncSetting.process_setting_id,
        }
        let newNcSetting = await GetProductionNcSetting(ncParams)
        if (newNcSetting.status !== -1) {
          newNcSetting = newNcSetting?.data ?? {}
          // 更新store里面的nc设置
          this.$store.commit('setNcSetting', {
            ...this.$store.state.ncSetting,
            ...newNcSetting,
          })
        }
      }
      const preTemp = this.cur_template
      const timeStamp = this.timeStamp
      if (isRefresh) {
        this.isNoMoreTag = false
        this.isTagLoading = false
        this.page = 1
        this.tag_svg_list = []

        this.resetLavePart(true)
      }

      //如果没有勾选生成裁剪板件标签过滤掉supplus标签
      if (!this.cut_need_lave) {
        this.tagListSourceData = this.tagListSourceData.filter(
          (i) => i.currentPlank?.specialType !== 'supplus'
        )
      }
      if (this.isNoMoreTag || this.isTagLoading) {
        return
      }

      // 加载期间不可下载文件
      this.isCanDownLoading = true
      let start = (this.page - 1) * this.limit
      let end = this.page * this.limit
      // 如果是余料标签
      if (this.isLoadLavePart) {
        start = this.tagListSourceData.length + (this.lavePage - 1) * this.limit
        end = this.tagListSourceData.length + this.lavePage * this.limit
      }
      let total = this.renderDataList.length
      if (isLoadAll) {
        // 当需要下载全部的时候,重置start和end
        start = this.tag_svg_list.length
        end = total
      }
      const isTemp = this.$route.query.from ? true : false
      this.isTagLoading = true
      let arr = []
      this.listDomWrap = document.querySelector('.tag_canvas_wap')
      if (this.listDomWrap) this.scrollTop = this.listDomWrap.scrollTop

      await this.timer(50)
      // 获取板件是否需要逆时针旋转
      let isRotatePlank = false
      const typographyKlass = this.cur_template.tem_data.find(
        (it) => it.type === 'Typography'
      )
      if (typographyKlass) {
        isRotatePlank = typographyKlass.data.plankRotate
      }

      // 对roomNameMap进行深拷贝，因为在生成标签过程中要对该值进行删除，为了不影响原始值，同时为了性能，因此在循环外进行此操作
      let copyRoomNameMap = null
      if (this.roomNameMap) {
        copyRoomNameMap = cloneDeep(this.roomNameMap)
      }
      //这里能拿到模板数据，获取当前模板同尺寸小板标签的数据
      const statisticsCount = countSameSizeBoard(
        this.renderDataList.filter((item) => item.type === 'tag')
      )
      // 彩色标签数据源判断逻辑
      // 订单 房间 柜体 任何一个数据源超过12个，则toast提示彩色标签不生效
      let judgeLimit = this.handleJudgeLimit('tag')
      for (let i = start; i < end; i++) {
        if (!this.isInCurrentRoute) {
          this.isTagLoading = false
          this.isCanDownLoading = false
          return
        }
        // 修改进度
        this.cut_progress = parseInt((100 / end) * (i + 1) + 0.001)
        // 进度完成才可下载
        if (this.cut_progress >= 100) {
          this.isCanDownLoading = false
        }
        let svg_html
        const current = this.renderDataList[i]
        if (!current) {
          break
        }
        // let size = ''
        // let graghEdge = {}
        // if (current.type === 'tag' || current.type === 'plank') {
        //   let graghEdgeRes = this.getGraghEdge(current.plank)
        //   size = graghEdgeRes.size
        //   graghEdge = graghEdgeRes.graghEdge
        // }
        if (current.type === 'tag') {
          // 生成标签大板尺寸
          const { size } = getGraghEdge(current.plank, this.ncSetting)
          current.plank.plankSize = size

          // 当是标签时
          this.judgeExistCustSpecTag(this.cur_template.tem_data)
          svg_html = await createTag(
            {
              ...this.cur_template,
              roomNameMap: copyRoomNameMap,
              print_color_tag: this.print_color_tag,
              judgeLimit,
              statisticsCount,
            },
            current.plank,
            current.part,
            current.plank_index,
            current.part_index,
            this.all_page,
            this.jx_checked,
            this.five_six_dir,
            { scale: this.canvas_scale },
            this.$store.state.ncSetting,
            // newNcSetting,
            current.currentPlank,
            this.collect,
            this.sort_value,
            isTemp,
            this.cut_need_lave,
            isRotatePlank,
            i
          )
        } else if (current.type === 'plank') {
          // 大板标签
          svg_html = await createTag(
            {
              ...bigPlankTemp,
              tag_width: this.cur_template.tag_width,
              tag_height: this.cur_template.tag_height,
            },
            {
              ...current.plank,
              isGenNcSuffix: this.isGenNcSuffix,
            },
            current.part, // current.part
            current.plank_index,
            null, // current.part_index
            this.all_page,
            this.jx_checked, // this.jx_checked
            false, // this.five_six_dir
            { scale: this.canvas_scale },
            this.$store.state.ncSetting,
            // newNcSetting,
            current.currentPlank,
            this.collect,
            this.sort_value,
            isTemp,
            false,
            isRotatePlank,
            i
          )
          const base64 = this.svgToBase64(svg_html)
          this.bigplantLableList.push(base64)
          this.bigplantLableList = Array.from(new Set(this.bigplantLableList))
        } else {
          // 当是余料标签时
          svg_html = await createLaveTag(
            this.cur_template,
            current.plank,
            current.plank_index,
            { scale: this.canvas_scale },
            this.$store.state.ncSetting,
            // newNcSetting,
            this.collect,
            isRotatePlank,
            this.all_page,
            this.part_page,
            i
          )
          this.lave_svg_list.push(svg_html)
          const base64 = this.svgToBase64(svg_html)
          this.LaveTagBase64List.push(base64)
          this.LaveTagBase64List = Array.from(new Set(this.LaveTagBase64List))
        }
        // 这儿应对生成大板标签选择的情况
        if (this.timeStamp !== timeStamp) {
          this.isTagLoading = false
          return
        }
        if (!this.isSameTemp(preTemp)) {
          this.isTagLoading = false
          return
        }
        if (isLoadAll) {
          // 避免在生成标签的时候切换生成余料标签
          if (this.need_lave) {
            end = this.renderDataList.length
          } else {
            if (i >= this.renderDataList.length) {
              // 此时表明标签已经生成完毕
              break
            } else {
              end = this.renderDataList.length
            }
          }
        }
        arr.push(svg_html)
        this.tag_svg_list.push(svg_html)
      }
      if (end >= total) {
        this.isNoMoreTag = true
      }

      // this.renderTag && this.renderTag.clear()
      if (!this.isSameTemp(preTemp)) {
        // this.listDomWrap.removeEventListener('scroll', this.keepScrollTop)
        this.isTagLoading = false
        return
      }
      // this.tag_svg_list.push(...arr)
      setTimeout(() => {
        // 这个是为了避免列表加载完毕以后的继续滚动
        // this.listDomWrap.removeEventListener('scroll', this.keepScrollTop)
        this.judgeLoadContinue(preTemp)
      }, 500)
      this.isTagLoading = false
      bus.$emit('waitGenAll')
      // 如果是全部下载
      if (isLoadAll) {
        // 修改page、lavePage值
        this.page = Math.ceil(this.tagListSourceData.length / this.limit)
        if (this.need_lave) {
          this.lavePage = Math.ceil(
            this.laveTagListSourceData.length / this.limit
          )
        }
        return
      }
      if (this.isLoadLavePart) {
        this.lavePage++
      } else {
        this.page++
      }
    },
    /**
     * 判断是标签模板中是否存在自定义特殊标签
     * @params {Array<Objiect>} tem_data 标签模板元素数组
     * */
    judgeExistCustSpecTag(tem_data) {
      this.$store.commit('setIsExistcusSpecialTag', false)
      tem_data.forEach((item) => {
        if (item.type === 'specialMark') {
          this.$store.commit('setIsExistcusSpecialTag', true)
        }
      })
    },
    svgToBase64(svgString) {
      return `data:image/svg+xml;charset=utf-8,${encodeURI(svgString)}`
    },
    isSameTemp(temp) {
      return JSON.stringify(temp) === JSON.stringify(this.cur_template)
    },
    keepScrollTop() {
      if (this.listDomWrap) {
        this.listDomWrap.scrollTop = this.scrollTop
      }
    },
    // 是否需要继续加载
    judgeLoadContinue(pre_template) {
      // debugger
      let wrap = document.querySelector('.tag_canvas_wap')
      let tagList = document.querySelector('.tag_list')
      if (pre_template && !this.isSameTemp(pre_template)) {
        return
      }
      if (
        tagList &&
        wrap &&
        wrap.offsetHeight > tagList.offsetHeight &&
        !this.isNoMoreTag
      ) {
        this.loadTagList()
        return
      }
    },
    // 筛选数据
    selectViewData(data) {
      if (this.cut_select_value && this.cut_select_value !== 'all') {
        let finalData = data.filter((item) => {
          const stockKey = item.stockKey.split(':').slice(0, 3).join(':')
          return stockKey === this.cut_select_value
        })
        return finalData
      }
      return data
    },

    handleColsChange() {
      this.saveConf()
    },
    handleColSpaceChange() {
      this.saveConf()
    },
    handleConfigSlider() {
      clearTimeout(timer)
      timer = setTimeout(() => {
        this.isreloading = true
        this.saveConf()
        this.isPaibanDataChange = true
        this.resetRequest()
      }, 300)
    },
    // 调用保存
    saveMaterialPaiban(url, auto_save) {
      const highgloss_direction = this.getHighgloss_direction
      let plankList = null
      let process_setting = null
      if (this.bigPartsInfo.length > 0) {
        plankList = this.bigPartsInfo
        process_setting = this.ncSetting
      }
      let addressArr = []
      this.$store.state.preLayoutData.forEach((part) => {
        const str = part.groupNo ?? part.address
        if (!addressArr.includes(str)) {
          addressArr.push(str)
        }
      })
      if (window.webToQt) {
        this.paibanWayName = `${addressArr.join('+')}_WebBluen_生产线(${
          this.ncSetting?.process_setting_name ?? ''
        })`
      } else {
        this.paibanWayName = `${addressArr.join('+')}_FromWebPage_生产线(${
          this.ncSetting?.process_setting_name ?? ''
        })`
      }
      const obj = {
        name: this.paibanWayName,
        plank_info: {
          plankTypeList: plankList,
          historyUrl: url,
          used_rate: this.paibanExtraInfo.total_used_rate
            ? this.paibanExtraInfo.total_used_rate
            : '',
          process_setting_name: process_setting
            ? process_setting.process_setting_name
            : '', // 记录生产线名字, 判断是否为默认生产线
          order_codes: this.orderInfo.order_codes
            ? this.orderInfo.order_codes
            : '',
          filterObj: this.filterObject,
          highgloss_direction,
          batch: this.orderInfo.batch
            ? Object.keys(this.orderInfo.batch).every((key) => {
                return this.orderInfo.batch[key] > 0
              })
            : false,
        },
        process_setting_id: process_setting
          ? process_setting.process_setting_id
          : -1,
        order_list: this.orderInfo.order_ids
          ? this.orderInfo.order_ids.split(',').map(Number)
          : [],
        file_type: this.paibanData.length ? 'paiban' : 'material',
        save_node: 3,
        generate_batch: this.orderInfo.batch
          ? Object.keys(this.orderInfo.batch).every((key) => {
              return this.orderInfo.batch[key] > 0
            })
          : false,
      }
      if (
        this.orderInfo.needPaibanRoomIds &&
        this.orderInfo.needPaibanRoomIds.length > 0
      ) {
        obj.room_ids = this.orderInfo.needPaibanRoomIds
      }
      if (auto_save) {
        obj.auto_save = auto_save
      }
      if (this.isHistoryStatus) {
        obj.plank_info.used_rate = this.historyOrder.plank_info.used_rate
      }
      this.$token('/save_material_data', obj, () => ({}))
    },
    autoSaveHistory() {
      const { obj, name } = this.ossFilesParams
      if (this.paibanData && this.paibanData.length) {
        ossUploadFiles(obj, name, (url) => {
          this.saveMaterialPaiban(url, 1)
        })
      }
    },
    // 鼠标监听事件
    handleShowTip() {
      let colorPop = document.getElementById('printTag2-color-pop')
      colorPop.style.display = 'block'
      colorPop.addEventListener('mouseover', this.handleShowTip)
      colorPop.addEventListener('mouseout', this.handleCloseTip)
    },
    handleCloseTip() {
      let colorPop = document.getElementById('printTag2-color-pop')
      colorPop.style.display = 'none'
    },
    // 判断彩色标签是否符合标准
    handleJudgeLimit(type) {
      let judgeLimit =
        this.$store.state.limitOvers.length && this.print_color_tag
      if (judgeLimit && type == 'tag') {
        this.$message.warning(
          `${this.translateLang('common.exist')}${this.$store.state.limitOvers
            .map((item) =>
              item == 'orderNo'
                ? this.translateLang('common.order')
                : item == 'roomName'
                ? this.translateLang('common.roomName')
                : this.translateLang('common.loc')
            )
            .join('/')}${this.translateLang('common.limitNotEffect')}`
        )
      }
      return judgeLimit
    },
    updatePreference({ data, isErr }) {
      if (isErr) return
      data = data || this.$store.state.preferencesSetting.setting
      // const data = res.data
      // 更新store模块中的偏好设置，后续需要使用store中的偏好设置替换页面中的使用方法
      // this.$store.commit('preferencesSetting/setSetting', data)
      // labelTemplate 模版
      // this.active_template_id = data.labelTemplate
      // surplusLabel 生成余料标签
      this.need_lave = data.surplusLabel

      // bigPlankLabel 生成大板信息标签
      this.gen_big_plank_tag = data.bigPlankLabel
      // gen_small_plank_tag 生成小板信息标签
      this.gen_small_plank_tag = data.genSmallPlankLabel ?? true
      // isGenNcSuffix 大板标签生成下料文件名后缀
      this.isGenNcSuffix = data.isGenNcSuffix ?? true
      // labelCoord 大板标签贴标坐标
      this.xCoordinate = data.labelCoord?.X ?? 0
      this.yCoordinate = data.labelCoord?.Y ?? 0
      // mirrorLabel 	标签镜像
      this.jx_checked = data.mirrorLabel
      // // boardBenchmark 生成五六面钻放板基准标识
      this.five_six_dir = data.boardBenchmark
      // labelSortWay 标签排序适应自动上下料
      this.sort_value = data.labelSortWay == 'default' ? 1 : data.labelSortWay
      //   printColorTag 生成彩色标签 默认不开启
      this.print_color_tag = data.printColorTag ?? false
      // labelColumnNum 标签列数
      this.print_setting.cols = data.labelColumnNum
      // labelColumnGap 多列标签列间距
      this.print_setting.labelColumnGap = data.labelColumnGap
      // 特殊标记透明度
      this.print_setting.opacity = data.opacity ?? 0.24
      // 特殊标记大小
      this.print_setting.size = data.size ?? 1
      // 特殊标记大小自适应开/关状态
      this.print_setting.sizeAuto = data.sizeAuto ?? true //默认开启
      this.cut_need_lave = data.cutNeedLave ?? true
      // TODO标记
      this.setDrawOpacity(this.print_setting.opacity)
      this.setDrawSize(this.print_setting.size)
      this.setDrawSizeAuto(this.print_setting.sizeAuto)
    },
  },
  computed: {
    ...mapState([
      'isSubPrintTag',
      'historyOrder',
      'userInfo',
      'isHistoryStatus',
      'historyPlankEdgeOff',
    ]),
    ...mapState(plankMapState),
    /** 是否条码错误 */
    isOneCodeErr() {
      if (this.cur_template) {
        const tempData = this.cur_template.tem_data
        const oneCodeList = tempData.filter((temp) => temp.type == 'Onecode')
        const hasOneCode = Boolean(oneCodeList.length)
        const notUniqueNum = oneCodeList.some(
          (temp) => temp.data.source_data != 'plankUniqueNum'
        )
        /** 是否勾选了板件条码唯一 */
        const hasSwitchPlankUnique = store.state.ncSetting.uniqueBarCode
        return hasOneCode && notUniqueNum && hasSwitchPlankUnique
      } else {
        return false
      }
    },
    getHighgloss_direction() {
      let highgloss_direction
      if (this.isHistoryStatus) {
        highgloss_direction = this.historyOrder.plank_info.highgloss_direction
          ? this.historyOrder.plank_info.highgloss_direction
          : 'back'
      } else {
        highgloss_direction = this.highgloss_direction
      }
      return highgloss_direction
    },
    ossFilesParams() {
      let obj = {
        layoutData: this.preLayoutData,
        paibanData: [],
        recodeSelectPart: this.recodeSelectPart,
        recodeSelectSurplus: this.recodeSelectSurplus,
        thinkerxMaterialKeys: this.$store.state.thinkerxMaterialKeys,
        paibanInfo: {
          historyPlankEdgeOff: this.historyPlankEdgeOff,
          historySecondTrimValue: this.ncSetting?.secondTrimValue,
        },
        filterPlankList:
          this.beforeSearchMaterialList ??
          this.beforeSizeFilterList ??
          this.filterMaterialList,
      }
      if (this.paibanData.length > 0) {
        obj.process_setting_id = this.ncSetting?.process_setting_id
        obj.process_setting_name = this.ncSetting?.process_setting_name
        obj.paibanData = this.paibanData
      }
      if (this.isShowSample) {
        obj.sampleFormData = this.sampleTableData
      }
      let time = new Date()
      let name =
        '/bluenMaterialData/' +
        time.getTime() +
        '_' +
        this.paibanWayName +
        '.json'
      return { name, obj }
    },
    finish_percent() {
      let total = 0
      total = this.renderDataList.length
      if (!total) return 100
      if (this.exportType === 'pdf') {
        return Math.floor((this.pdfPageCount / total) * 100)
      }

      return Math.floor((this.tag_svg_list.length / total) * 100)
    },
    tag_canvas_wap_width() {
      if (!this.cur_template) return { total_width: 0, space: 0 }
      let dpi = getDeviceXDPI()
      let tag_width =
        mmToPx(this.cur_template.tag_width, dpi) * this.canvas_scale
      let space =
        mmToPx(this.print_setting.labelColumnGap, dpi) * this.canvas_scale
      return {
        total_width:
          tag_width * this.print_setting.cols +
          space * (this.print_setting.cols - 1),
        space,
      }
    },
    renderDataList() {
      return this.need_lave
        ? [...this.tagListSourceData, ...this.laveTagListSourceData]
        : [...this.tagListSourceData]
    },
    disabledTagList() {
      return this.isTagLoading || this.isNoMoreTag
    },
    all_page() {
      if (this.need_lave) {
        return [...this.tagListSourceData, ...this.laveTagListSourceData].length
      }
      return this.tagListSourceData.length
    },
    part_page() {
      return this.tagListSourceData.length
    },
    isWebToQt() {
      return Boolean(window.webToQt)
    },
    // 根据renderDataList，统计出当前一共有多少房间，并且返回一个房间名和字母/数字的一个映射关系
    roomNameMap() {
      // 筛选出tag的标签数据
      const tagDataList = this.renderDataList.filter(
        (item) => item.type === 'tag'
      )
      return roomNameToChartMap(tagDataList)
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.params.bigpart && this.$route.params.bigpart.stockNum) {
          this.currentBigpart = this.$route.params.bigpart
        } else {
          this.currentBigpart = null
        }
        // if (this.$route.path == '/printTag' && this.$route.query.from) {
        //   this.isPaibanDataChange = true
        //   this.resetRequest()
        // }
      },
    },
    '$store.state.ncSetting.front_labeling_start_num': {
      handler() {
        this.initData()
        this.startRender()
      },
    },
  },
  async created() {
    this.resetRequest()
  },
  mounted() {
    // this.addBusEvent()

    if (window.webToQt) {
      this.inQt = true
    } else {
      this.inQt = false
    }
    if (this.$route.params.bigpart && this.$route.params.bigpart.stockNum) {
      this.currentBigpart = this.$route.params.bigpart
    } else {
      this.currentBigpart = null
    }
    // parintTag2-error-circle 鼠标悬浮时显示提示
    let error_circle = document.getElementById('parintTag2-error-circle')
    // 添加鼠标悬停事件监听
    error_circle.addEventListener('mouseover', this.handleShowTip)
    error_circle.addEventListener('mouseout', this.handleCloseTip)
  },
  beforeDestroy() {
    // this.removeBusEvent()
  },
  // 模板编辑退出刷新页面
  beforeRouteEnter(to, from, next) {
    if (from.name == 'editTagTem') {
      next((vm) => {
        vm.isPaibanDataChange = true
        vm.resetRequest()
      })
    }
    next(true)
  },
  async activated() {
    // 挂载偏好设置的更新
    this.$preference.init(this.updatePreference, this.$route.path)
    this.isInCurrentRoute = true
    // this.isCanDownLoading = true
    if (JSON.stringify(this.$store.state.paibanData) !== this.afterPaibanData) {
      this.isSameTypeSet = false
      this.resetRequest()
      this.isPaibanDataChange = true
    } else {
      // 如果是当前模板
      this.isSameTypeSet = true
      if (!this.isNoMoreTag) {
        // 如果没有下载完，继续加载
        this.loadTagList(false, true)
      }
    }
  },
  deactivated() {
    this.isInCurrentRoute = false
  },
  components: {
    breadcrumb,
    loading,
    GBaseModal,
    GFolderChooseModal,
    newContent,
  },
}
</script>

<style lang="less">
.print_tag_wapper {
  min-height: calc(100vh - 60px);
  background-color: #eeeeee;
  .breadcrumb-box {
    position: relative;
    z-index: 1;
    height: 46px;
    padding: 0 !important;
    .bread-crumb-box {
      display: flex;
      align-items: center;
      height: 46px !important;
      background-color: #eee !important;
      .ant-breadcrumb {
        padding: 0 !important;
        padding-left: 16px !important;
        margin-top: 2px !important;
      }
    }
    .bread-crumb-box .bread-crumb-box-content {
      display: none !important;
    }
    .select-tag {
      position: absolute;
      bottom: 10px;
      left: 320px;
      display: flex;
      align-items: center;

      .el-icon-loading {
        margin-left: 5px;
        color: #18a8c7;
        font-size: 16px;
      }

      .progress {
        display: block;
        width: 180px;
        margin-left: 5px;
      }
    }
  }

  .com_content {
    display: flex;
    width: 100%;

    .tag_setTem_wap {
      width: 280px;
      height: calc(100vh - 183px);
      overflow-x: hidden;
      background-color: #fff;

      > div {
        padding: 16px 0px 0px 16px;
        .title {
          display: flex;
          align-items: center;
          font-weight: bold;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.9);
        }
      }

      .tag_tem_wap {
        height: 250px;
        border-bottom: 1px solid #e7e7e7;
        .tag_tem_list {
          max-height: 140px;
          overflow-x: hidden;
          > div {
            height: 34px;
            padding-left: 42px;
            line-height: 34px;
            &.active {
              color: rgba(0, 0, 0, 0.9);
              position: relative;
              &::before {
                position: absolute;
                left: 16px;
                top: 9px;
                content: '';
                width: 16px;
                height: 16px;
                background: url('~@/assets/icon/check@2x.png') no-repeat center;
                background-size: 16px;
              }
            }
            &:hover {
              background-color: #f3f3f3;
              border-radius: 4px;
              color: rgba(0, 0, 0, 0.6);
            }
          }
          .template-name {
            display: block;
            white-space: nowrap;
          }
        }
      }
      .tag_set {
        border-bottom: 1px solid #e7e7e7;
        .ant-checkbox-wrapper {
          color: rgba(0, 0, 0, 0.9);
          display: flex;
          align-items: center;
        }
        > div > p {
          margin-bottom: 16px;
        }
      }

      .label-position {
        margin-left: 24px;
        font-size: 12px !important;
        .coord-box {
          width: 105px;
          height: 26px;
          border: 1px solid #dcdcdc;
        }
        .ant-input-number {
          border: none;
          box-shadow: none;
          &:focus {
            box-shadow: none;
          }
        }
        .ant-input-number-input {
          height: 24px;
          padding-top: 1px;
        }

        .el-input-number {
          width: 60px !important;
          margin-left: 2px !important;
        }

        .el-input-number__decrease,
        .el-input-number__increase {
          width: 20px !important;
        }

        .el-input__inner {
          padding-right: 20px !important;
          padding-left: 0 !important;
          font-size: 12px !important;
        }
      }
      .tag_sort_check {
        margin-bottom: 10px;

        .ant-radio-wrapper {
          margin-right: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 75px;
          height: 30px;
          display: flex;
          align-items: center;
          span {
            display: inline-block;
          }
          span.ant-radio + * {
            display: flex;
            padding-right: 12px;
            color: rgba(0, 0, 0, 0.9);
          }
        }
      }
      .tag_color_check {
        margin-bottom: 30px;
        .label-color-pop {
          width: 320px;
          min-height: 520px;
          max-height: 550px;
          flex-shrink: 0;
          border-radius: 3px;
          box-shadow: 0 6px 30px 5px #0000000d, 0 16px 24px 2px #0000000a,
            0 8px 10px -5px #00000014;
          z-index: 999;
          background-color: #fff;
          top: 165px;
          left: 160px;
          display: none;
          overflow-y: scroll;
          scrollbar-width: none; /* Firefox */
          -ms-overflow-style: none;
          .label-color-phead {
            color: #000000e6;
          }
          .label-color-title {
            line-height: 22px;
          }
          .label-color-span {
            display: inline-block;
            width: 133px;
            line-height: 20px;
            color: #00000066;
          }
          .label-color-img {
            width: 139px;
            height: 88px;
            margin-left: 16px;
          }
        }
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .print_set {
        .ant-input-number {
          border-radius: 3px;
          border: 1px solid #dcdcdc;
        }
        .ant-slider {
          .ant-slider-track {
            background-color: #18a8c7;
          }
          .ant-slider-handle {
            border-color: #18a8c7;
          }
        }
      }
    }

    .tag_canvas_wap {
      position: relative;
      flex: 1;
      max-height: calc(100vh - 183px);
      padding: 10px;
      overflow: auto;
      text-align: center;
      background-color: #eee;

      &.tag_canvas_wap--height {
        // 主要是为了防止页面loading由于滚动条造成的跳动
        .loading_page-wrap,
        .tag_list {
          min-height: calc(100% + 100px);
        }
      }
    }

    .tag_list {
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      color: rgba(0, 0, 0, 1) !important;
    }

    .tag_canvas_wap > div {
      margin: 0 auto;
    }

    .tag_print_box {
      position: relative;
      width: 256px;
      height: 252px;
      background-color: #d9d9d9;
      border: 1px solid rgba(255, 255, 255, 0.9);
      .legend {
        .icon {
          display: inline-block;
          width: 24px;
          height: 24px;
          text-align: center;
        }

        .open {
          line-height: 24px;
          border: 1px solid #fff;
          border-radius: 50%;
        }
      }
      .print_btn {
        position: absolute;
        bottom: 20px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        width: 100%;
        height: 140px;
        padding: 0 30px;
      }

      .percent_box {
        position: absolute;
        bottom: 180px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        width: 100%;
        height: 100px;
        padding: 0 50px;
      }
    }

    .render_svg_item {
      transform-origin: left top;
    }
    .ant-radio-group {
      margin-right: 0px;
    }
  }
  .header {
    height: 77px;
    border-bottom: 1px solid #e7e7e7;
    .title {
      margin-right: 200px;
    }
    .progress {
      display: block;
      width: 180px;
      margin-left: 5px;
    }
    &-right {
      right: 24px;
      .export-btn {
        height: 34px;
        border-radius: 6px;
      }
      .export-pdf-btn:not([disabled='disabled']) {
        background-color: #18a8c7;
        color: #fff;
      }
      .print-btn {
        width: 60px;
        height: 34px;
        background-color: #18a8c7;
        border-radius: 6px;
        line-height: 32px;
        color: rgba(255, 255, 255, 0.9);
        border: none;
      }
    }
  }
  .print-tip {
    width: 320px;
    height: 76px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 6px 30px 5px #0000000d, 0 16px 24px 2px #0000000a,
      0 8px 10px -5px #00000014;
    padding: 16px;
    top: 45px;
    right: -15px;
    z-index: 999;
    pointer-events: none;
    display: none;
    .color-tip {
      line-height: 22px;
      white-space: wrap;
    }
  }
  .print-tag-img:hover + .print-tip {
    display: block;
  }
}
.text-style {
  max-width: 200px;
  line-height: 100%;
  font-weight: normal;
}
.disabled-btn {
  color: #999 !important;
  background-color: #f3f0f0 !important;
  &:hover {
    color: #999 !important;
    background-color: #f3f0f0 !important;
  }
}
</style>
<style lang="less" scoped>
/deep/ .new-button_text {
  right: -35px;
}
</style>
